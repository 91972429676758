import React, { useState } from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  ExternalLink,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  NamedLink,
  FaqDropdown,
} from '../../components';
import { TopbarContainer } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import css from './StartSellingInfoPage.css';
import infoImage from '../../assets/start_selling_page_freelancer.webp';
import marketingSalesIcon from '../../assets/marketing-sales-icon.svg';
import clockMoneyIcon from '../../assets/clock-money-icon.svg';
import rocketIcon from '../../assets/rocket-icon.svg';
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';

const MOBILE_REVIEW_BREAKPOINT = 720;

export const FreelancerInfoComponent = props => {
  const { intl, scrollingDisabled } = props;

  const [currentReviews, setCurrentReviews] = useState(0);
  const [currentMobileReview, setCurrentMobileReview] = useState(0);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'StartSellingInfoPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'StartSellingInfoPage.schemaDescription' });

  const howItWorksCardTitle1 = <FormattedMessage id="StartSellingInfoPage.howItWorksCardTitle1" />;
  const howItWorksCardTitle2 = <FormattedMessage id="StartSellingInfoPage.howItWorksCardTitle2" />;
  const howItWorksCardTitle3 = <FormattedMessage id="StartSellingInfoPage.howItWorksCardTitle3" />;
  const howItWorksCardTitle4 = <FormattedMessage id="StartSellingInfoPage.howItWorksCardTitle4" />;

  const howItWorksCardText1 = (
    <FormattedMessage
      id="StartSellingInfoPage.howItWorksCardText1"
      values={{
        lineBreak: <br />,
      }}
    />
  );
  const howItWorksCardText2 = (
    <FormattedMessage
      id="StartSellingInfoPage.howItWorksCardText2"
      values={{
        lineBreak: <br />,
      }}
    />
  );
  const howItWorksCardText3 = (
    <FormattedMessage
      id="StartSellingInfoPage.howItWorksCardText3"
      values={{
        lineBreak: <br />,
      }}
    />
  );
  const howItWorksCardText4 = (
    <FormattedMessage
      id="StartSellingInfoPage.howItWorksCardText4"
      values={{
        lineBreak: <br />,
      }}
    />
  );

  const HowItWorksCard = ({ title, description }) => {
    return (
      <div className={css.howItWorksCard}>
        <div className={css.howItWorksCardTitle}>{title}</div>
        <div className={css.howItWorksCardDescription}>{description}</div>
      </div>
    );
  };

  const cardTitle1 = <FormattedMessage id="StartSellingInfoPage.whyFreedomlyCardTitle1" />;
  const cardTitle2 = <FormattedMessage id="StartSellingInfoPage.whyFreedomlyCardTitle2" />;
  const cardTitle3 = <FormattedMessage id="StartSellingInfoPage.whyFreedomlyCardTitle3" />;

  const cardDescription1 = (
    <FormattedMessage id="StartSellingInfoPage.whyFreedomlyCardDescription1" />
  );
  const cardDescription2 = (
    <FormattedMessage id="StartSellingInfoPage.whyFreedomlyCardDescription2" />
  );
  const cardDescription3 = (
    <FormattedMessage id="StartSellingInfoPage.whyFreedomlyCardDescription3" />
  );

  const WhyFreedomlyContentCard = ({ title, description, icon }) => {
    return (
      <div className={css.whyFreedomlyContentCard}>
        <div className={css.whyFreedomlyContentCardIcon}>
          <img src={icon} alt="icon" />
        </div>
        <div className={css.whyFreedomlyCardTextSection}>
          <div className={css.whyFreedomlyContentCardTitle}>{title}</div>
          <div className={css.whyFreedomlyContentCardDescription}>{description}</div>
        </div>
      </div>
    );
  };

  const reviews = [
    [
      {
        text: 'StartSellingInfoPage.reviewText1',
        name: 'StartSellingInfoPage.reviewName1',
        title: 'StartSellingInfoPage.reviewTitle1',
      },
      {
        text: 'StartSellingInfoPage.reviewText2',
        name: 'StartSellingInfoPage.reviewName2',
        title: 'StartSellingInfoPage.reviewTitle2',
      },
      {
        text: 'StartSellingInfoPage.reviewText3',
        name: 'StartSellingInfoPage.reviewName3',
        title: 'StartSellingInfoPage.reviewTitle3',
      },
    ],
    [
      {
        text: 'StartSellingInfoPage.reviewText4',
        name: 'StartSellingInfoPage.reviewName4',
        title: 'StartSellingInfoPage.reviewTitle4',
      },
      {
        text: 'StartSellingInfoPage.reviewText5',
        name: 'StartSellingInfoPage.reviewName5',
        title: 'StartSellingInfoPage.reviewTitle5',
      },
      {
        text: 'StartSellingInfoPage.reviewText6',
        name: 'StartSellingInfoPage.reviewName6',
        title: 'StartSellingInfoPage.reviewTitle6',
      },
    ],
  ];

  const ReviewSection = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth > MOBILE_REVIEW_BREAKPOINT ? (
        <>
          <ReviewCards reviews={reviews} reviewsId={currentReviews} />
          <div className={css.sectionReviewsBottomRow}>
            <div className={css.sectionReviewsArrows}>
              <BsArrowLeftCircle className={css.arrow} onClick={() => prevReviews()} />
              <BsArrowRightCircle className={css.arrow} onClick={() => nextReviews()} />
            </div>
            <NamedLink name="SignupProviderPage" className={css.sectionReviewsCTA}>
              <FormattedMessage id="StartSellingInfoPage.heroCTA" />
            </NamedLink>
          </div>
        </>
      ) : (
        <>
          <MobileReviewCard reviews={reviews.flat(Infinity)} reviewsId={currentMobileReview} />
          <div className={css.sectionReviewsBottomRow}>
            <div className={css.sectionReviewsArrows}>
              <BsArrowLeftCircle className={css.arrow} onClick={() => prevMobileReview()} />
              <BsArrowRightCircle className={css.arrow} onClick={() => nextMobileReview()} />
            </div>
            <NamedLink name="SignupProviderPage" className={css.sectionReviewsCTA}>
              <FormattedMessage id="StartSellingInfoPage.heroCTA" />
            </NamedLink>
          </div>
        </>
      );
    }
  };

  const ReviewCards = ({ reviews, reviewsId }) => {
    return (
      <div className={css.sectionReviewsContainer}>
        {reviews[reviewsId].map(review => (
          <div className={css.reviewCard} key={review.text}>
            <div className={css.reviewCardDescription}>
              <FormattedMessage id={review.text} />
            </div>
            <div className={css.reviewCardBottomContainer}>
              <div className={css.reviewCardName}>
                <FormattedMessage id={review.name} />
              </div>
              <div className={css.reviewCardTitle}>
                <FormattedMessage id={review.title} />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const MobileReviewCard = ({ reviews, reviewsId }) => {
    return (
      <div className={css.reviewCardMobile} key={reviews[reviewsId].text}>
        <div className={css.reviewCardDescription}>
          <FormattedMessage id={reviews[reviewsId].text} />
        </div>
        <div className={css.reviewCardBottomContainer}>
          <div className={css.reviewCardName}>
            <FormattedMessage id={reviews[reviewsId].name} />
          </div>
          <div className={css.reviewCardTitle}>
            <FormattedMessage id={reviews[reviewsId].title} />
          </div>
        </div>
      </div>
    );
  };

  const nextReviews = () => {
    if (currentReviews === reviews.length - 1) {
      setCurrentReviews(0);
    } else {
      setCurrentReviews(currentReviews + 1);
    }
  };

  const prevReviews = () => {
    if (currentReviews === 0) {
      setCurrentReviews(reviews.length - 1);
    } else {
      setCurrentReviews(currentReviews - 1);
    }
  };

  const nextMobileReview = () => {
    if (currentMobileReview === reviews.flat(Infinity).length - 1) {
      setCurrentMobileReview(0);
    } else {
      setCurrentMobileReview(currentMobileReview + 1);
    }
  };

  const prevMobileReview = () => {
    if (currentMobileReview === 0) {
      setCurrentMobileReview(reviews.flat(Infinity) - 1);
    } else {
      setCurrentMobileReview(currentMobileReview - 1);
    }
  };

  const [faqIsShown, setFaqIsShown] = useState([
    {
      question: <FormattedMessage id="StartSellingInfoPage.sectionFAQQuestion1" />,
      answer: (
        <FormattedMessage
          id="StartSellingInfoPage.sectionFAQAnswer1"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 0,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id="StartSellingInfoPage.sectionFAQQuestion2" />,
      answer: (
        <FormattedMessage
          id="StartSellingInfoPage.sectionFAQAnswer2"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 1,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id="StartSellingInfoPage.sectionFAQQuestion3" />,
      answer: (
        <FormattedMessage
          id="StartSellingInfoPage.sectionFAQAnswer3"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 2,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id="StartSellingInfoPage.sectionFAQQuestion4" />,
      answer: (
        <FormattedMessage
          id="StartSellingInfoPage.sectionFAQAnswer4"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 3,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id="StartSellingInfoPage.sectionFAQQuestion5" />,
      answer: (
        <FormattedMessage
          id="StartSellingInfoPage.sectionFAQAnswer5"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 4,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id="StartSellingInfoPage.sectionFAQQuestion6" />,
      answer: (
        <FormattedMessage
          id="StartSellingInfoPage.sectionFAQAnswer6"
          values={{
            b: chunks => <b>{chunks}</b>,
            lineBreak: <br />,
          }}
        />
      ),
      index: 5,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id="StartSellingInfoPage.sectionFAQQuestion7" />,
      answer: (
        <FormattedMessage
          id="StartSellingInfoPage.sectionFAQAnswer7"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 6,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id="StartSellingInfoPage.sectionFAQQuestion8" />,
      answer: (
        <FormattedMessage
          id="StartSellingInfoPage.sectionFAQAnswer8"
          values={{
            a: chunks => (
              <ExternalLink href="https://help.freedomly.io/fi/articles/6727816-freedomly-kevytyrittajalle">
                {chunks}
              </ExternalLink>
            ),
            lineBreak: <br />,
          }}
        />
      ),
      index: 7,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
  ]);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="StartSellingInfoPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <ul className={css.sections}>
            <div className={css.heroSection}>
              <div className={css.heroContent}>
                <div className={css.heroTitle}>
                  <FormattedMessage
                    id="StartSellingInfoPage.heroTitle"
                    values={{
                      lineBreak: <br />,
                    }}
                  />
                </div>
                <div className={css.heroSubtitle}>
                  <FormattedMessage id="StartSellingInfoPage.heroSubtitle" />
                </div>
                <NamedLink name="SignupProviderPage" className={css.heroCTA}>
                  <FormattedMessage id="StartSellingInfoPage.heroCTA" />
                </NamedLink>
              </div>
            </div>
            <li className={css.section}>
              <div className={css.whyFreedomlyContainer}>
                <div className={css.whyFreedomlyTitle}>
                  <FormattedMessage id="StartSellingInfoPage.whyFreedomlyTitle" />
                </div>
                <div className={css.whyFreedomlyContentContainer}>
                  <div className={css.whyFreedomlyContentLeft}>
                    <WhyFreedomlyContentCard
                      title={cardTitle1}
                      description={cardDescription1}
                      icon={marketingSalesIcon}
                    />
                    <WhyFreedomlyContentCard
                      title={cardTitle2}
                      description={cardDescription2}
                      icon={clockMoneyIcon}
                    />
                    <WhyFreedomlyContentCard
                      title={cardTitle3}
                      description={cardDescription3}
                      icon={rocketIcon}
                    />
                  </div>
                  <div className={css.whyFreedomlyContentRight}>
                    <div className={css.whyFreedomlyInfoSectionContentImageContainer}>
                      <div className={css.whyFreedomlyInfoSectionContentImage}>
                        <img src={infoImage} alt="Freelancer" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={css.whyFreedomlySubTitle}>
                  <NamedLink name="SignupProviderPage">
                    <FormattedMessage id="StartSellingInfoPage.whyFreedomlyCTA" />
                  </NamedLink>
                </div>
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionReviewsContent}>
                <div className={css.sectionReviewsTitle}>
                  <FormattedMessage id="StartSellingInfoPage.sectionReviewsTitle" />
                </div>
                {ReviewSection()}
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionHowItWorksContent}>
                <div className={css.howItWorksTitle}>
                  <FormattedMessage id="StartSellingInfoPage.howItWorksTitle" />
                </div>
                <div className={css.howItWorksCardContainer}>
                  <HowItWorksCard title={howItWorksCardTitle1} description={howItWorksCardText1} />
                  <HowItWorksCard title={howItWorksCardTitle2} description={howItWorksCardText2} />
                  <HowItWorksCard title={howItWorksCardTitle3} description={howItWorksCardText3} />
                  <HowItWorksCard title={howItWorksCardTitle4} description={howItWorksCardText4} />
                </div>
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionFAQContent}>
                <div className={css.sectionFAQTitle}>
                  <FormattedMessage id="StartSellingInfoPage.sectionFAQTitle" />
                </div>
                <FaqDropdown faqIsShown={faqIsShown} setFaqIsShown={setFaqIsShown} />
              </div>
            </li>
          </ul>
          <div className={css.contactSection}>
            <div className={css.contactSectionTitle}>
              <FormattedMessage id="StartSellingInfoPage.contactSectionTitle" />
            </div>
            <div className={css.contactSectionSubTitle}>
              <FormattedMessage id="StartSellingInfoPage.contactSectionSubtitle" />
            </div>
            <NamedLink name="SignupPage" className={css.heroCTA}>
              <FormattedMessage id="StartSellingInfoPage.heroCTA" />
            </NamedLink>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const StartSellingInfoPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(FreelancerInfoComponent);

export default StartSellingInfoPage;
