import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';

import {
  Page,
  Footer,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  NamedLink,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage } from '../../util/reactIntl';

import facebookImage from '../../assets/freedomlySocialShare.png';
import twitterImage from '../../assets/freedomlySocialShare.png';

import howItWorksTile1Image from '../../assets/dashboard-marketplace-post-job.webp';
import howItWorksTile2Image from '../../assets/dashboard-marketplace-ordering-work.webp';
import howItWorksTile3Image from '../../assets/dashboard-marketplace-completing-the-work.webp';

import css from './DashboardMarketplacePage.css';

const DashboardMarketplacePageComponent = props => {
  const { scrollingDisabled, intl } = props;

  const schemaTitle = intl.formatMessage({ id: 'DashboardMarketplacePage.schemaTitle' });
  const schemaDescription = intl.formatMessage({
    id: 'DashboardMarketplacePage.schemaDescription',
  });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const handleBookCall = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  const HowItWorksTile = props => {
    const { number, titleId, descriptionId, imgSrc, showLine } = props;

    const line = <div className={css.howItWorksTileLine}></div>;

    return (
      <div className={css.howItWorksTile}>
        <div className={css.howItWorksTileLeft}>
          <div className={css.howItWorksTileNumber}>{number}</div>
          {showLine ? line : null}
        </div>
        <div className={css.howItWorksTileMiddle}>
          <div className={css.howItWorksTileTitle}>
            <FormattedMessage id={titleId} />
          </div>
          <div className={css.howItWorksTileDescription}>
            <FormattedMessage
              id={descriptionId}
              values={{
                lineBreak: <br />,
              }}
            />
          </div>
        </div>
        <div className={css.howItWorksTileRight}>
          <img src={imgSrc} alt="How it works tile" />
        </div>
      </div>
    );
  };

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[
        { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutWrapperTopbar>
        <TopbarContainer currentPage="DashboardMarketplacePage" />
      </LayoutWrapperTopbar>
      <LayoutWrapperMain>
        <div className={css.sections}>
          <div className={css.headerSection}>
            <div className={css.headerBreadcrumbs}>
              <FormattedMessage
                id="DashboardMarketplacePage.breadcrumbsDashboard"
                values={{
                  a: chunks => <NamedLink name="DashboardPage">{chunks}</NamedLink>,
                }}
              />
              <FormattedMessage
                id="DashboardMarketplacePage.breadcrumbsDashboardMarketplacePage"
                values={{
                  a: chunks => <NamedLink name="DashboardMarketplacePage">{chunks}</NamedLink>,
                }}
              />
            </div>
            <div className={css.headerTitle}>
              <FormattedMessage id="DashboardMarketplacePage.title" />
            </div>
            <div className={css.headerDescription}>
              <FormattedMessage id="DashboardMarketplacePage.description" />
            </div>
          </div>
          <div className={css.howItWorksSection}>
            <div className={css.howItWorksTitle}>
              <FormattedMessage id="DashboardMarketplacePage.howItWorksTitle" />
            </div>
            <div className={css.howItWorksTiles}>
              <HowItWorksTile
                number="1"
                titleId="DashboardMarketplacePage.howItWorksTileTitle1"
                descriptionId="DashboardMarketplacePage.howItWorksTileDescription1"
                imgSrc={howItWorksTile1Image}
                showLine={true}
              />
              <HowItWorksTile
                number="2"
                titleId="DashboardMarketplacePage.howItWorksTileTitle2"
                descriptionId="DashboardMarketplacePage.howItWorksTileDescription2"
                imgSrc={howItWorksTile2Image}
                showLine={true}
              />
              <HowItWorksTile
                number="3"
                titleId="DashboardMarketplacePage.howItWorksTileTitle3"
                descriptionId="DashboardMarketplacePage.howItWorksTileDescription3"
                imgSrc={howItWorksTile3Image}
                showLine={false}
              />
            </div>
          </div>
          <div className={css.paymentSection}>
            <div className={css.paymentTitle}>
              <FormattedMessage id="DashboardMarketplacePage.paymentTitle" />
            </div>
            <div className={css.paymentSectionBankcardTile}>
              <div className={css.paymentSectionTileTitle}>
                <FormattedMessage id="DashboardMarketplacePage.paymentSectionBankcardTileTitle" />
              </div>
              <div className={css.paymentSectionTileDescriptionBold}>
                <FormattedMessage id="DashboardMarketplacePage.paymentSectionBankcardTileDescription" />
              </div>
              <div className={css.paymentSectionTileDivider}></div>
              <div className={css.paymentSectionBankCardSteps}>
                <div className={css.paymentSectionBankCardStep}>
                  <div className={css.paymentSectionBankCardStepNumber}>1</div>
                  <div className={css.paymentSectionBankCardStepContent}>
                    <FormattedMessage id="DashboardMarketplacePage.paymentSectionBankCardStep1Title" />
                    <FormattedMessage id="DashboardMarketplacePage.paymentSectionBankCardStep1Description" />
                  </div>
                </div>
                <div className={css.paymentSectionBankCardStep}>
                  <div className={css.paymentSectionBankCardStepNumber}>2</div>
                  <div className={css.paymentSectionBankCardStepContent}>
                    <FormattedMessage id="DashboardMarketplacePage.paymentSectionBankCardStep2Title" />
                    <FormattedMessage id="DashboardMarketplacePage.paymentSectionBankCardStep2Description" />
                  </div>
                </div>
                <div className={css.paymentSectionBankCardStep}>
                  <div className={css.paymentSectionBankCardStepNumber}>3</div>
                  <div className={css.paymentSectionBankCardStepContent}>
                    <FormattedMessage id="DashboardMarketplacePage.paymentSectionBankCardStep3Title" />
                    <FormattedMessage id="DashboardMarketplacePage.paymentSectionBankCardStep3Description" />
                  </div>
                </div>
              </div>
            </div>
            <div className={css.paymentSectionInvoiceTile}>
              <div className={css.paymentSectionTileCaption}>
                <FormattedMessage id="DashboardMarketplacePage.paymentSectionInvoiceTileCaption" />
              </div>
              <div className={css.paymentSectionTileTitle}>
                <FormattedMessage id="DashboardMarketplacePage.paymentSectionInvoiceTileTitle" />
              </div>
              <div className={css.paymentSectionTileDescription}>
                <FormattedMessage id="DashboardMarketplacePage.paymentSectionInvoiceTileDescription" />
              </div>
              <div className={css.paymentSectionTileDivider}></div>
              <div className={css.paymentSectionTileCTA}>
                <div className={css.paymentSectionCTA} onClick={handleBookCall}>
                  <FormattedMessage id="DashboardMarketplacePage.bookCallCTA" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutWrapperMain>
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const DashboardMarketplacePage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps),
  injectIntl
)(DashboardMarketplacePageComponent);

export default DashboardMarketplacePage;
