import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './Avatar.css';

export const OffPlatformReviewAvatarComponent = props => {
  const { rootClassName, className, abbreviatedName } = props;
  const classes = classNames(rootClassName || css.root, className);

  const rootProps = { className: classes };

  return (
    <div {...rootProps}>
      <span className={css.initials}>{abbreviatedName}</span>
    </div>
  );
};

OffPlatformReviewAvatarComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

OffPlatformReviewAvatarComponent.propTypes = {
  rootClassName: string,
  className: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OffPlatformReviewAvatar = injectIntl(OffPlatformReviewAvatarComponent);

export default OffPlatformReviewAvatar;
