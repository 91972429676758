import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  SectionCustomerLogos,
  ProFaqSection,
  BookCallBanner,
  SuccessStoryBanner,
  IntegrateFreelancersBanner,
  SectionHero,
  SectionLocations,
  HowItWorksTiles,
  SectionExpandingToPro,
  ProContactSection,
} from '../../components';

import succesStoryBannerImage from '../../assets/tulus-success-story-banner.webp';
import IntegrateFreelancersBannerImg from '../../assets/integrate-freelancers-banner-image.webp';

import { TopbarContainer } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ProLandingPage.css';

export const ProLandingPageComponent = props => {
  const { intl, scrollingDisabled, history, location, viewport } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const schemaTitle = intl.formatMessage({ id: 'ProLandingPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({ id: 'ProLandingPage.schemaDescription' });

  const heroTitle1 = <FormattedMessage id="ProLandingPage.SectionHeroTitle" />;
  const heroTitle2 = <FormattedMessage id="ProLandingPage.SectionHeroTitle2" />;
  const heroSubtitle = <FormattedMessage id="ProLandingPage.SectionHeroSubTitle" />;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ProLandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.heroContainer}>
                <SectionHero
                  history={history}
                  location={location}
                  heroTitle1={heroTitle1}
                  heroTitle2={heroTitle2}
                  heroSubtitle={heroSubtitle}
                  atLandingPage={false}
                  noButton={true}
                  atProLandingPage={true}
                />
              </div>
            </li>
            <li className={css.section}>
              <SectionCustomerLogos
                toShow={[
                  'peikko',
                  'olefit',
                  'eerikkila',
                  'corego',
                  'mobal',
                  'strongest',
                  'systam',
                  'zervant',
                ]}
              />
            </li>
            <li className={css.section}>
              <SectionExpandingToPro />
            </li>
            <li className={css.section}>
              <SuccessStoryBanner
                imgSrc={succesStoryBannerImage}
                textId={'SuccessStoryBanner.tulusText'}
                fromId={'SuccessStoryBanner.tulusFrom'}
                companyId={'SuccessStoryBanner.tulusCompany'}
                pageName={'SuccessStoryTulusPage'}
                buttonTxtId={'SuccessStoryBanner.tulusCTAText'}
              />
            </li>
            <div className={css.section}>
              <HowItWorksTiles atProLandingPage={true} />
            </div>
            <li className={css.section}>
              <BookCallBanner />
            </li>
            <li className={css.section}>
              <IntegrateFreelancersBanner
                imgSrc={IntegrateFreelancersBannerImg}
                titleId="DigitalAgencySolutionPage.integrateFreelancersBannerTitle"
                textId="DigitalAgencySolutionPage.integrateFreelancersBannerText"
              />
            </li>
            <li className={css.section}>
              <ProFaqSection />
            </li>
            <li className={css.section}>
              <SectionLocations />
            </li>
          </ul>
          <ProContactSection viewport={viewport} />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ProLandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(ProLandingPageComponent);

export default ProLandingPage;
