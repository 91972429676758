import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  AvatarLarge,
  ProfileSettingsContainer as Container,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  NamedLink,
  SuccessStoryBanner,
} from '../../components';
import { LeaveOffPlatformTestimonialForm } from '../../forms';
import config from '../../config';
import { NotFoundPage, TopbarContainer } from '..';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { withViewport } from '../../util/contextHelpers';
import { ensureUser } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';
import { loadData, postOffPlatformTestimonial } from './LeaveOffPlatformTestimonialPage.duck';

import facebookImage from '../../assets/freedomlySocialShare.png';
import twitterImage from '../../assets/freedomlySocialShare.png';
import succesStoryBannerImage from '../../assets/tulus-success-story-banner.webp';

import css from './LeaveOffPlatformTestimonialPage.css';

const { UUID } = sdkTypes;

const LeaveOffPlatformTestimonialPageComponent = props => {
  const {
    scrollingDisabled,
    user,
    userShowError,
    currentOffPlatformTestimonial,
    userFullName,
    onLeaveOffPlatformTestimonial,
    intl,
  } = props;

  const profileUser = ensureUser(user);
  const displayName = userFullName?.fullName
    ? userFullName?.fullName
    : profileUser?.attributes?.profile?.displayName;
  const workExperience = profileUser?.attributes?.profile?.publicData?.workExperience;
  const profileUserId = profileUser?.id?.uuid;

  const currentWorkExperienceForTestimonial = workExperience?.find(
    e => e.id === currentOffPlatformTestimonial?.workExperienceId
  );

  const handleLeaveOffPlatformTestimonial = values => {
    onLeaveOffPlatformTestimonial(
      profileUser.id.uuid,
      currentWorkExperienceForTestimonial.id,
      values.content,
      values.authorPosition,
      values.authorName
    );
  };

  const CurrentWorkExperienceForTestimonialContainer = () => {
    return (
      <div className={css.workExperienceContainer}>
        <Container>
          <div className={classNames(css.asideContent, css.container)}>
            <div className={css.bioNameContainer}>
              <AvatarLarge className={css.avatar} user={user} disableProfileLink />
              <div className={css.displayName}>{displayName}</div>
            </div>
            <div className={css.workExperienceInfo}>
              <div className={css.workExperienceCustomerName}>
                {currentWorkExperienceForTestimonial?.customerName}
              </div>
              <div className={css.workExperienceTask}>
                {currentWorkExperienceForTestimonial?.task}
              </div>
              <div className={css.workExperienceDates}>
                {currentWorkExperienceForTestimonial?.startMonth}
                {'/'}
                {currentWorkExperienceForTestimonial?.startYear} -{' '}
                {currentWorkExperienceForTestimonial?.jobContinues
                  ? intl.formatMessage({ id: 'LeaveOffPlatformTestimonialPage.ongoing' })
                  : `${currentWorkExperienceForTestimonial?.endMonth}/${currentWorkExperienceForTestimonial?.endYear}`}
              </div>
            </div>
          </div>

          <div className={css.workExperienceDescription}>
            {currentWorkExperienceForTestimonial?.description}
          </div>
        </Container>
      </div>
    );
  };

  const showForm = currentOffPlatformTestimonial?.state === 'requested';

  const mainContent = (
    <div className={css.mainContent}>
      <CurrentWorkExperienceForTestimonialContainer />
      {showForm ? (
        <LeaveOffPlatformTestimonialForm
          currentOffPlatformTestimonial={currentOffPlatformTestimonial}
          currentWorkExperienceForTestimonial={currentWorkExperienceForTestimonial}
          displayName={displayName}
          onSubmit={handleLeaveOffPlatformTestimonial}
        />
      ) : currentOffPlatformTestimonial?.state === 'reviewed' && profileUserId ? (
        <div className={css.testimonialState}>
          <div className={css.testimonialReviewedContainer}>
            <article>
              <h2>
                <FormattedMessage id="LeaveOffPlatformTestimonialPage.testimonialReviewed" />
              </h2>
              <p className={css.messageContainer}>
                <FormattedMessage
                  id="LeaveOffPlatformTestimonialPage.testimonialReviewedMessage"
                  values={{
                    a: chunks => (
                      <NamedLink name="ProfilePage" params={{ id: profileUserId }}>
                        {chunks}
                      </NamedLink>
                    ),
                  }}
                />
              </p>
              <h2 className={css.freedomlyTitle}>
                <FormattedMessage id="LeaveOffPlatformTestimonialPage.testimonialPublishedFreedomlyTitle" />
              </h2>
              <p>
                <FormattedMessage id="LeaveOffPlatformTestimonialPage.testimonialReviewedMessage2" />
              </p>
              <div className={css.successStoryBannerContainer}>
                <SuccessStoryBanner
                  imgSrc={succesStoryBannerImage}
                  textId={'SuccessStoryBanner.tulusText'}
                  fromId={'SuccessStoryBanner.tulusFrom'}
                  companyId={'SuccessStoryBanner.tulusCompany'}
                  pageName={'SuccessStoriesPage'}
                  buttonTxtId={'LeaveOffPlatformTestimonialPage.successStoryBannerButton'}
                />
              </div>
            </article>
          </div>
        </div>
      ) : currentOffPlatformTestimonial?.state === 'published' && profileUserId ? (
        <div className={css.testimonialState}>
          <div className={css.testimonialReviewedContainer}>
            <article>
              <h2>
                <FormattedMessage id="LeaveOffPlatformTestimonialPage.testimonialPublished" />
              </h2>
              <p className={css.messageContainer}>
                <FormattedMessage
                  id="LeaveOffPlatformTestimonialPage.testimonialPublishedMessage"
                  values={{
                    a: chunks => (
                      <NamedLink name="ProfilePage" params={{ id: profileUserId }}>
                        {chunks}
                      </NamedLink>
                    ),
                  }}
                />
              </p>
              <h2 className={css.freedomlyTitle}>
                <FormattedMessage id="LeaveOffPlatformTestimonialPage.testimonialPublishedFreedomlyTitle" />
              </h2>
              <p>
                <FormattedMessage id="LeaveOffPlatformTestimonialPage.testimonialPublishedMessage2" />
              </p>
              <div className={css.successStoryBannerContainer}>
                <SuccessStoryBanner
                  imgSrc={succesStoryBannerImage}
                  textId={'SuccessStoryBanner.tulusText'}
                  fromId={'SuccessStoryBanner.tulusFrom'}
                  companyId={'SuccessStoryBanner.tulusCompany'}
                  pageName={'SuccessStoriesPage'}
                  buttonTxtId={'LeaveOffPlatformTestimonialPage.successStoryBannerButton'}
                />
              </div>
            </article>
          </div>
        </div>
      ) : null}
    </div>
  );

  let content;

  if (userShowError && userShowError.status === 404) {
    return <NotFoundPage history={''} location={''} />;
  } else if (userShowError) {
    content = (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingDataFailed" />
      </p>
    );
  } else {
    content = mainContent;
  }

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[
        { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="LeaveOffPlatformTestimonialPage"
            authenticationPageTopBar={true}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.main}>
          <div className={css.content}>{content}</div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LeaveOffPlatformTestimonialPageComponent.defaultProps = {
  user: null,
  userShowError: null,
};

const { bool, number, shape } = PropTypes;

LeaveOffPlatformTestimonialPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  user: propTypes.user,
  userShowError: propTypes.error,
  querycurrentOffPlatformTestimonialError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    userId,
    userShowError,
    currentOffPlatformTestimonial,
    querycurrentOffPlatformTestimonialError,
    userFullName,
    userFullNameError,
  } = state.LeaveOffPlatformTestimonialPage;

  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    user,
    userShowError,
    currentOffPlatformTestimonial,
    querycurrentOffPlatformTestimonialError,
    userFullName,
    userFullNameError,
  };
};

const mapDispatchToProps = dispatch => ({
  onLeaveOffPlatformTestimonial: (
    freelancerId,
    workExperienceId,
    content,
    authorPosition,
    authorName
  ) =>
    dispatch(
      postOffPlatformTestimonial(
        freelancerId,
        workExperienceId,
        content,
        authorPosition,
        authorName
      )
    ),
});

const LeaveOffPlatformTestimonialPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(LeaveOffPlatformTestimonialPageComponent);

LeaveOffPlatformTestimonialPage.loadData = params => {
  const userId = new UUID(params.userId);
  const workExperienceId = new UUID(params.workExperienceId);
  return loadData(userId, workExperienceId);
};

export default LeaveOffPlatformTestimonialPage;
