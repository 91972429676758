import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import {
  IconSocialMediaInstagram,
  IconSocialMediaLinkedIn,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.css';

const renderSocialMediaLinks = intl => {
  const { siteInstagramPage, siteLinkedInPage } = config;

  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToLinkedIn = intl.formatMessage({ id: 'Footer.goToLinkedIn' });

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const linkedInLink = siteLinkedInPage ? (
    <ExternalLink
      key="linkToLinkedIn"
      href={siteLinkedInPage}
      className={css.icon}
      title={goToLinkedIn}
    >
      <IconSocialMediaLinkedIn />
    </ExternalLink>
  ) : null;
  return [instragramLink, linkedInLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  const handleOpenContact = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="desktop" className={css.logo} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationAddress1" />
                  <FormattedMessage id="Footer.organizationAddress2" />
                </p>
                <div className={css.someLinks}>{socialMediaLinks}</div>
              </div>
            </div>
            <ul className={css.list}>
              <li className={css.listItemHeader}>
                <FormattedMessage id="Footer.solutionsHeader" />
              </li>
              <li className={css.listItem}>
                <NamedLink name="InHouseSolutionPage" className={css.link}>
                  <FormattedMessage id="Footer.toSolutionInHousePage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="OrganizationSolutionPage" className={css.link}>
                  <FormattedMessage id="Footer.toSolutionOrganizationPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="StartupSolutionPage" className={css.link}>
                  <FormattedMessage id="Footer.toSolutionStartUpPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="MarketingSolutionPage" className={css.link}>
                  <FormattedMessage id="Footer.toSolutionMarketingPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="CommunicationSolutionPage" className={css.link}>
                  <FormattedMessage id="Footer.toSolutionCommunicationPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="CreativeContentSolutionPage" className={css.link}>
                  <FormattedMessage id="Footer.toSolutionCreativePage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="ProLandingPage" className={css.link}>
                  <FormattedMessage id="Footer.toProLandingPage" />
                </NamedLink>
              </li>
            </ul>
            <ul className={css.list}>
              <li className={css.listItemHeader}>
                <FormattedMessage id="Footer.infoHeader" />
              </li>
              <li className={css.listItem}>
                <NamedLink name="SuccessStoriesPage" className={css.link}>
                  <FormattedMessage id="Footer.successStoriesPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="ReviewsPage" className={css.link}>
                  <FormattedMessage id="Footer.toReviews" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="StartBuyingInfoPage" className={css.link}>
                  <FormattedMessage id="Footer.howItWorks" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="StartSellingInfoPage" className={css.link}>
                  <FormattedMessage id="Footer.toFreelancer" />
                </NamedLink>
              </li>
            </ul>
            <ul className={css.list}>
              <li className={css.listItemHeader}>
                <FormattedMessage id="Footer.freedomlyHeader" />
              </li>
              <li className={css.listItem}>
                <NamedLink name="AboutUsPage" className={css.link}>
                  <FormattedMessage id="Footer.toAboutPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <ExternalLink onClick={handleOpenContact} className={css.link}>
                  <FormattedMessage id="Footer.toContactPage" />
                </ExternalLink>
              </li>
              <li className={css.listItem}>
                <ExternalLink href="https://help.freedomly.io/fi/" className={css.link}>
                  <FormattedMessage id="Footer.toFAQPage" />
                </ExternalLink>
              </li>
              <li>
                <NamedLink name="TermsOfServicePage" className={css.link}>
                  <FormattedMessage id="Footer.termsOfUse" />
                </NamedLink>
              </li>
              <li>
                <NamedLink name="PrivacyPolicyPage" className={css.link}>
                  <FormattedMessage id="Footer.privacyPolicy" />
                </NamedLink>
              </li>
            </ul>
          </div>
          <div>
            <p className={css.organizationCopyright}>
              <NamedLink name="LandingPage" className={css.copyrightLink}>
                <FormattedMessage id="Footer.copyright" />
              </NamedLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
