import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '../../components';

import howItWorksTile1Image from '../../assets/dashboard-pro-call.webp';
import howItWorksTile2Image from '../../assets/dashboard-pro-introductions.webp';
import howItWorksTile3Image from '../../assets/dashboard-pro-getWorkDone.webp';

import css from './HowItWorksTiles.css';

const HowItWorksTiles = props => {
  const { atProLandingPage } = props;
  const HowItWorksTile = props => {
    const { number, titleId, descriptionId, imgSrc, showCTA, showLine } = props;

    const handleShowSpaceHome = () => {
      if (typeof window === 'object') {
        window.Intercom('showSpace', 'home');
      }
    };

    const cta = (
      <ExternalLink className={css.howItWorksTileCTA} onClick={handleShowSpaceHome}>
        <FormattedMessage id="ProLandingPage.heroContactButton" />
      </ExternalLink>
    );

    const line = <div className={css.howItWorksTileLine}></div>;

    return (
      <div className={css.howItWorksTile}>
        <div className={css.howItWorksTileLeft}>
          <div className={css.howItWorksTileNumber}>{number}</div>
          {showLine ? line : null}
        </div>
        <div className={css.howItWorksTileMiddle}>
          <div className={css.howItWorksTileTitle}>
            <FormattedMessage id={titleId} />
          </div>
          <div className={css.howItWorksTileDescription}>
            <FormattedMessage id={descriptionId} />
          </div>

          {showCTA ? cta : null}
        </div>
        <div className={css.howItWorksTileRight}>
          <img src={imgSrc} alt="How it works tile" />
        </div>
      </div>
    );
  };

  return (
    <div className={css.howItWorksSection}>
      <div className={atProLandingPage ? css.proLandingPageTitle : css.howItWorksTitle}>
        <FormattedMessage id="DashboardProPage.howItWorksTitle" />
      </div>
      <div className={css.howItWorksTiles}>
        <HowItWorksTile
          number="1"
          titleId="DashboardProPage.howItWorksTileTitle1"
          descriptionId="DashboardProPage.howItWorksTileDescription1"
          imgSrc={howItWorksTile1Image}
          showCTA={true}
          showLine={true}
        />
        <HowItWorksTile
          number="2"
          titleId="DashboardProPage.howItWorksTileTitle2"
          descriptionId="DashboardProPage.howItWorksTileDescription2"
          imgSrc={howItWorksTile2Image}
          showCTA={false}
          showLine={true}
        />
        <HowItWorksTile
          number="3"
          titleId="DashboardProPage.howItWorksTileTitle3"
          descriptionId="DashboardProPage.howItWorksTileDescription3"
          imgSrc={howItWorksTile3Image}
          showCTA={false}
          showLine={false}
        />
      </div>
    </div>
  );
};

export default HowItWorksTiles;
