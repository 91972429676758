import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Page,
  SectionHero,
  SectionReviewStats,
  SectionReviewsVideo,
  SectionReviews,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/freedomlySocialShare.png';
import twitterImage from '../../assets/freedomlySocialShare.png';
import css from './ReviewsPage.css';
import { propTypes } from '../../util/types';

export const ReviewsPageComponent = props => {
  const { history, intl, location, scrollingDisabled } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'ReviewsPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'ReviewsPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const heroTitle1 = <FormattedMessage id="ReviewsPage.SectionHeroTitle" />;
  const heroTitle2 = <FormattedMessage id="ReviewsPage.SectionHeroTitle2" />;
  const heroSubtitle = <FormattedMessage id="ReviewsPage.SectionHeroSubTitle" />;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[
        { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="LandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.heroContainer}>
                <SectionHero
                  history={history}
                  location={location}
                  heroTitle1={heroTitle1}
                  heroTitle2={heroTitle2}
                  heroSubtitle={heroSubtitle}
                  atLandingPage={false}
                  noButton={true}
                />
              </div>
            </li>
            <li className={css.section}>
              <SectionReviewStats />
            </li>
            <li className={css.section}>
              <SectionReviewsVideo />
            </li>
            <li className={css.section}>
              <SectionReviews />
            </li>
            <li className={css.section}>
              <SectionLocations />
            </li>
          </ul>
          <div className={css.contactSection}>
            <div className={css.contactSectionTitle}>
              <FormattedMessage id="SectionContactLandingPage.title" />
            </div>
            <div className={css.contactSectionSubTitle}>
              <FormattedMessage id="SectionContactLandingPage.subtitle" />
            </div>
            <NamedLink name="SignupPage" className={css.contactSectionButton}>
              <FormattedMessage id="SectionContactLandingPage.buttonText" />
            </NamedLink>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

ReviewsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  currentUser: propTypes.user,
  currentUserFetchInProgress: bool.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserFetchInProgress } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentUserFetchInProgress,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ReviewsPage = compose(withRouter, connect(mapStateToProps), injectIntl)(ReviewsPageComponent);

// ReviewsPage.loadData = loadData;

export default ReviewsPage;
