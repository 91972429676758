import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { string } from 'prop-types';
import classNames from 'classnames';
import {
  Avatar,
  OffPlatformReviewAvatar,
  ReviewRating,
  UserDisplayName,
  OffPlatformUserDisplayName,
} from '../../components';

import css from './Reviews.css';

const Review = props => {
  const { review, intl } = props;

  const date = review.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  return (
    <div className={css.review}>
      {review.onPlatform ? (
        <Avatar className={css.avatar} user={review.author} disableProfileLink={true} />
      ) : (
        <OffPlatformReviewAvatar className={css.avatar} abbreviatedName={review.abbreviatedName} />
      )}

      <div>
        <ReviewRating
          rating={review.rating}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />
        <p className={css.reviewContent}>{review.content}</p>
        <p className={css.reviewInfo}>
          {review.onPlatform ? (
            <UserDisplayName user={review.author} intl={intl} />
          ) : (
            <OffPlatformUserDisplayName authorName={review.authorName} intl={intl} />
          )}

          <span className={css.separator}>•</span>
          {dateString}
          <span className={css.desktopSeparator}>•</span>
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
        </p>
      </div>
    </div>
  );
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <ul className={classes}>
      {reviews.map(r => {
        return (
          <li key={`Review_${r.id}`} className={css.reviewItem}>
            <Review review={r} intl={intl} />
          </li>
        );
      })}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  // reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
