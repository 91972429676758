import React from 'react';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { PrimaryButton, SecondaryButton, NamedLink, Form, FieldTextInput } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import { CiWarning } from 'react-icons/ci';
import * as validators from '../../util/validators';

import css from './ApplicationPanel.css';

const ActionsComponent = props => {
  const [afterSkip, setAfterSkip] = React.useState(false);

  const handleSubmitLogic = values => {
    const enhancedValues = {
      ...values,
      afterSkip: afterSkip,
    };
    props.onSubmit(enhancedValues);
  };

  return (
    <FinalForm
      {...props}
      mutators={{ setFieldData }}
      onSubmit={handleSubmitLogic}
      render={fieldRenderProps => {
        const {
          formId,
          handleSubmit,
          intl,
          authorDisplayName,
          values,
          transaction,
          transactionId,
          showActionButtons,
          showAcceptAfterSkipButton,
          sendAcceptApplicationInProgress,
          showProfileListingInProgress,
          sendCreateReverseTransactionInProgress,
          sendSkipApplicationInProgress,
          sendAcceptAfterSkipInProgress,
          onSkipApplication,
          showToNewTransactionButton,
          isCustomer,
        } = fieldRenderProps;

        const buttonsDisabled =
          sendAcceptApplicationInProgress ||
          showProfileListingInProgress ||
          sendCreateReverseTransactionInProgress ||
          sendSkipApplicationInProgress ||
          sendAcceptAfterSkipInProgress;

        const newTransactionId = transaction.attributes.protectedData.newTransactionId;

        const warningIcon = <CiWarning />;

        const messagePlaceholder = intl.formatMessage(
          {
            id: 'ApplicationPanel.messagePlaceholder',
          },
          { authorDisplayName }
        );
        const messageRequiredMessage = intl.formatMessage({
          id: 'EnquiryForm.messageRequired',
        });

        const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

        const submitDisabled = values.message ? false : true;

        return (
          <Form className={css.form} onSubmit={handleSubmit}>
            {showActionButtons && (
              <div className={css.formWrapper}>
                <FieldTextInput
                  className={css.field}
                  type="textarea"
                  name="message"
                  id={formId ? `${formId}.message` : 'message'}
                  placeholder={messagePlaceholder}
                  validate={messageRequired}
                  icon={warningIcon}
                />
                <PrimaryButton
                  className={css.submitButton}
                  inProgress={
                    sendAcceptApplicationInProgress ||
                    showProfileListingInProgress ||
                    sendCreateReverseTransactionInProgress
                  }
                  disabled={submitDisabled}
                  type="submit"
                  onClick={() => {
                    setAfterSkip(false);
                  }}
                >
                  <FormattedMessage id="ApplicationPanel.startConversation" />
                </PrimaryButton>
                <p className={css.skipDescription}>
                  <FormattedMessage id="ApplicationPanel.skipDescription" />
                </p>
                <SecondaryButton
                  className={css.actionButton}
                  inProgress={sendSkipApplicationInProgress}
                  disabled={buttonsDisabled}
                  onClick={() => onSkipApplication(transactionId)}
                >
                  <FormattedMessage id="ApplicationPanel.freezeApplication" />
                </SecondaryButton>
              </div>
            )}
            {showAcceptAfterSkipButton && (
              <div className={css.formWrapper}>
                <FieldTextInput
                  className={css.field}
                  type="textarea"
                  name="message"
                  id={formId ? `${formId}.message` : 'message'}
                  placeholder={messagePlaceholder}
                  validate={messageRequired}
                  icon={warningIcon}
                />
                <PrimaryButton
                  className={css.submitButton}
                  inProgress={
                    sendAcceptApplicationInProgress ||
                    showProfileListingInProgress ||
                    sendCreateReverseTransactionInProgress
                  }
                  disabled={buttonsDisabled}
                  type="submit"
                  onClick={() => {
                    setAfterSkip(true);
                  }}
                >
                  <FormattedMessage id="ApplicationPanel.startConversation" />
                </PrimaryButton>
              </div>
            )}
            {showToNewTransactionButton && (
              <div className={css.toNewProjectWrapper}>
                <NamedLink
                  className={css.toNewTransactionButton}
                  name={isCustomer ? 'SaleDetailsPage' : 'OrderDetailsPage'}
                  params={{ id: newTransactionId }}
                >
                  <FormattedMessage id="ApplicationPanel.toNewTransaction" />
                </NamedLink>
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};

const Actions = compose(injectIntl)(ActionsComponent);

export default Actions;
