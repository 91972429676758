import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '..';
import { FaUserCog } from 'react-icons/fa';

import css from './ModalMissingInformation.css';

const ProfileCompleteReminder = props => {
  const {
    className,
    hasBio,
    hasWhoAmI,
    hasSkills,
    hasLocation,
    hasLanguages,
    hasHourlyFee,
    hasProfileImage,
    hasCocAccepted,
  } = props;

  return (
    <div className={className}>
      <FaUserCog className={css.modalIconProfile} />
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.missingProfileInformationTitle" />
      </p>
      <ul>
        {!hasWhoAmI && (
          <li className={css.modalMessageListItem}>
            <FormattedMessage id="ModalMissingInformation.stepWhoAmIDescription" />
          </li>
        )}
        {!hasBio && (
          <li className={css.modalMessageListItem}>
            <FormattedMessage id="ModalMissingInformation.stepBioDescription" />
          </li>
        )}
        {!hasSkills && (
          <li className={css.modalMessageListItem}>
            <FormattedMessage id="ModalMissingInformation.stepExperienceDescription" />
          </li>
        )}
        {!hasLocation && (
          <li className={css.modalMessageListItem}>
            <FormattedMessage id="ModalMissingInformation.stepLocationDescription" />
          </li>
        )}
        {!hasLanguages && (
          <li className={css.modalMessageListItem}>
            <FormattedMessage id="ModalMissingInformation.stepLanguagesDescription" />
          </li>
        )}
        {!hasHourlyFee && (
          <li className={css.modalMessageListItem}>
            <FormattedMessage id="ModalMissingInformation.stepHourlyFeeDescription" />
          </li>
        )}
        {!hasProfileImage && (
          <li className={css.modalMessageListItem}>
            <FormattedMessage id="ModalMissingInformation.stepProfilePicDescription" />
          </li>
        )}
        {!hasCocAccepted && (
          <li className={css.modalMessageListItem}>
            <FormattedMessage id="ModalMissingInformation.stepAcceptCOCDescription" />
          </li>
        )}
      </ul>
      <NamedLink className={css.reminderModalLinkButtonProfile} name="ProfileSettingsPage">
        <FormattedMessage id="ModalMissingInformation.toProfileCTA" />
      </NamedLink>
    </div>
  );
};

export default ProfileCompleteReminder;
