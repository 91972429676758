import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { injectIntl } from '../../util/reactIntl';
import {
  Form,
  SecondaryButton,
  Button,
  FieldSelectModern,
  IconClose,
  InlineTextButton,
  LocationAutocompleteInputField,
  FieldRadioButton,
} from '../../components';
import { required } from '../../util/validators';
import config from '../../config';

import css from './SignupProviderForms.css';

const languageLevelOptions = config.custom.languageLevelOptions;

const identity = v => v;

const SignupProviderLocationNLanguageForm = props => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          onPrev,
          form,
          intl,
          form: {
            mutators: { push },
          },
          filterConfig,
          values,
          invalid,
          isModal,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid;

        const optionsTranslated = languageLevelOptions.map(x => ({
          ...x,
          label: intl.formatMessage({ id: x.label }),
        }));

        const heading = intl.formatMessage({ id: 'SignupProviderForms.locationNLanguageHeading' });

        const nextButtonText = intl.formatMessage({ id: 'SignupProviderForms.nextButton' });
        const previousButtonText = intl.formatMessage({ id: 'SignupProviderForms.previousButton' });
        const addButtonText = intl.formatMessage({ id: 'SignupProviderForms.addButton' });
        const okButtonText = intl.formatMessage({ id: 'SignupProviderForms.okButton' });

        const locationPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.locationNLanguageLocationPlaceholder',
        });

        const workingMethodLabel = intl.formatMessage({
          id: 'SignupProviderForms.locationNLanguageWorkingMethodLabel',
        });

        const subHeading = intl.formatMessage({
          id: 'SignupProviderForms.locationNLanguageSubHeading',
        });

        const languagePlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.locationNLanguageLanguagePlaceholder',
        });

        const levelPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.locationNLanguageLevelPlaceholder',
        });

        const languages = filterConfig.find(filter => filter.id === 'languages').config.options;
        const languagesWithValue = languages.map(lang => ({
          ...lang,
          value: lang.key,
          label: intl.formatMessage({ id: lang.label.props.id }),
        }));

        const locationRequired = intl.formatMessage({ id: 'SignupProviderForms.locationRequired' });
        const workingMethodRequired = intl.formatMessage({
          id: 'SignupProviderForms.workingMethodRequired',
        });
        const languageRequired = intl.formatMessage({ id: 'SignupProviderForms.languageRequired' });
        const levelRequired = intl.formatMessage({ id: 'SignupProviderForms.levelRequired' });

        const workingMethodRemoteNInPerson = intl.formatMessage({
          id: 'SignupProviderForms.workingMethodRemoteNInPerson',
        });
        const workingMethodRemote = intl.formatMessage({
          id: 'SignupProviderForms.workingMethodRemote',
        });

        return (
          <div className={classes}>
            {!isModal && <h2 className={css.mainHeading}>{heading}</h2>}
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              {!isModal ? (
                <>
                  <LocationAutocompleteInputField
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    name="location"
                    placeholder={locationPlaceholder}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.location}
                    validate={required(locationRequired)}
                    predictionType="place"
                  />
                  <div className={css.workingMethod}>
                    <h2 className={css.subHeading}>{workingMethodLabel}</h2>
                    <div className={css.workingMethods}>
                      <FieldRadioButton
                        id="workingMethodOption1"
                        name="workingMethod"
                        label={workingMethodRemoteNInPerson}
                        value="remoteNInPerson"
                        checkboxIcon
                        validate={required(workingMethodRequired)}
                      />
                      <FieldRadioButton
                        id="workingMethodOption2"
                        name="workingMethod"
                        label={workingMethodRemote}
                        value="remote"
                        checkboxIcon
                        validate={required(workingMethodRequired)}
                      />
                    </div>
                  </div>
                </>
              ) : null}

              <h2 className={css.subHeading}>
                {subHeading}
                {isModal ? (
                  <div className={css.submitContainer}>
                    <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                      {okButtonText}
                    </Button>
                  </div>
                ) : null}
              </h2>
              <FieldArray name="languages">
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => (
                      <div key={name} className={css.fieldArray}>
                        <FieldSelectModern
                          id={`${name}.language`}
                          name={`${name}.language`}
                          options={languagesWithValue}
                          placeholder={languagePlaceholder}
                          className={css.fieldArrayOption}
                          validate={required(languageRequired)}
                        />
                        <FieldSelectModern
                          id={`${name}.level`}
                          name={`${name}.level`}
                          options={optionsTranslated}
                          placeholder={levelPlaceholder}
                          className={css.fieldArrayLevel}
                          validate={required(levelRequired)}
                        />
                        <span
                          onClick={() =>
                            fields?.length === 1 ? fields.update(index, {}) : fields.remove(index)
                          }
                        >
                          <IconClose rootClassName={css.deleteIcon} />
                        </span>
                      </div>
                    ))}
                  </>
                )}
              </FieldArray>
              <InlineTextButton
                type="button"
                className={css.addToArray}
                onClick={() => push('languages', {})}
              >
                {addButtonText}
              </InlineTextButton>
              <div className={css.submitContainer}>
                {!isModal && (
                  <SecondaryButton rootClassName={css.backButton} onClick={onPrev}>
                    {previousButtonText}
                  </SecondaryButton>
                )}
                {!isModal ? (
                  <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                    {nextButtonText}
                  </Button>
                ) : null}
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

SignupProviderLocationNLanguageForm.defaultProps = {
  filterConfig: config.custom.filters,
  isModal: false,
};

export default injectIntl(SignupProviderLocationNLanguageForm);
