import React from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { injectIntl } from '../../util/reactIntl';
import {
  Form,
  FieldTextInput,
  SecondaryButton,
  Button,
  FieldSelectModern,
  IconClose,
  InlineTextButton,
  FieldCheckbox,
  LinkedInAuthButton,
} from '../../components';
import { required } from '../../util/validators';
import { generateYearsList } from '../../util/dates';

import css from './SignupProviderForms.css';

const SignupProviderEducationForm = props => {
  const requiredMessage = id => required(props.intl.formatMessage({ id }));

  const validateEducation = (fieldSet, dontAddEducation) => {
    if (dontAddEducation) return;

    const fieldsToValidate = ['where', 'degree', 'industry', 'when'];
    const error = {};

    fieldsToValidate.forEach(field => {
      error[field] = requiredMessage(`SignupProviderForms.${field}Required`)(fieldSet[field]);
    });

    return error;
  };
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      validate={values => {
        const errors = {};
        errors.education = values.education?.map(item =>
          validateEducation(item, values.dontAddEducation)
        );
        return errors;
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          onPrev,
          form,
          intl,
          form: {
            mutators: { push },
          },
          invalid,
          values,
          isModal,
          initialValues,
        } = formRenderProps;

        const skipStep = values.dontAddEducation;
        const submitDisabled = invalid && !skipStep;
        const changed =
          JSON.stringify(initialValues.education) !== JSON.stringify(values.education);
        const modalSubmitDisabled = invalid || !changed;

        const classes = classNames(rootClassName || css.root, className);

        const heading = intl.formatMessage({ id: 'SignupProviderForms.educationHeading' });

        const nextButtonText = intl.formatMessage({ id: 'SignupProviderForms.nextButton' });
        const previousButtonText = intl.formatMessage({ id: 'SignupProviderForms.previousButton' });
        const addButtonText = intl.formatMessage({ id: 'SignupProviderForms.addButton' });
        const okButtonText = intl.formatMessage({ id: 'SignupProviderForms.okButton' });

        const whereLabel = intl.formatMessage({ id: 'SignupProviderForms.educationWhereLabel' });
        const wherePlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.educationWherePlaceholder',
        });

        const degreeLabel = intl.formatMessage({
          id: 'SignupProviderForms.educationDegreeLabel',
        });
        const degreePlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.educationDegreePlaceholder',
        });

        const industryLabel = intl.formatMessage({
          id: 'SignupProviderForms.educationIndustryLabel',
        });
        const industryPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.educationIndustryPlaceholder',
        });

        const whenLabel = intl.formatMessage({ id: 'SignupProviderForms.educationWhenLabel' });
        const whenPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.educationWhenPlaceholder',
        });

        const dontAddEducationLabel = intl.formatMessage({
          id: 'SignupProviderForms.educationDontAddEducationLabel',
        });

        return (
          <div className={classes}>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <h2 className={css.mainHeading}>
                {heading}
                {isModal ? (
                  <div className={css.submitContainer}>
                    <Button
                      rootClassName={css.nextButton}
                      type="Submit"
                      disabled={modalSubmitDisabled}
                    >
                      {okButtonText}
                    </Button>
                  </div>
                ) : null}
              </h2>
              <div className={css.column}>
                {isModal && (
                  <LinkedInAuthButton
                    redirectUri={'profile-settings'}
                    modal={'education'}
                    isModal={isModal}
                  />
                )}
                <FieldArray name="education">
                  {({ fields }) => (
                    <>
                      {fields.map((name, index) => (
                        <div key={name} className={css.form}>
                          <span
                            onClick={() =>
                              fields?.length === 1 ? fields.update(index, {}) : fields.remove(index)
                            }
                            className={css.alignEnd}
                          >
                            <IconClose rootClassName={css.deleteIcon} />
                          </span>

                          <FieldTextInput
                            id={`${name}.where`}
                            name={`${name}.where`}
                            type="text"
                            placeholder={wherePlaceholder}
                            label={whereLabel}
                            disabled={values.dontAddEducation}
                          />
                          <FieldTextInput
                            id={`${name}.degree`}
                            name={`${name}.degree`}
                            type="text"
                            placeholder={degreePlaceholder}
                            label={degreeLabel}
                            disabled={values.dontAddEducation}
                          />
                          <FieldTextInput
                            id={`${name}.industry`}
                            name={`${name}.industry`}
                            type="text"
                            placeholder={industryPlaceholder}
                            label={industryLabel}
                            disabled={values.dontAddEducation}
                          />
                          <FieldSelectModern
                            id={`${name}.when`}
                            name={`${name}.when`}
                            options={generateYearsList(1900, 5)}
                            placeholder={whenPlaceholder}
                            label={whenLabel}
                            disabled={values.dontAddEducation}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
                <div className={css.column}>
                  <InlineTextButton
                    type="button"
                    className={css.addToArray}
                    onClick={() => push('education', {})}
                    disabled={values.dontAddEducation}
                  >
                    {addButtonText}
                  </InlineTextButton>
                  <FieldCheckbox
                    id="dontAddEducation"
                    name="dontAddEducation"
                    label={dontAddEducationLabel}
                    defaultValue={false}
                  />
                </div>
              </div>
              <div className={css.submitContainer}>
                {!isModal && (
                  <SecondaryButton rootClassName={css.backButton} onClick={onPrev}>
                    {previousButtonText}
                  </SecondaryButton>
                )}
                {!isModal ? (
                  <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                    {nextButtonText}
                  </Button>
                ) : null}
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

SignupProviderEducationForm.defaultProps = { isModal: false };

export default injectIntl(SignupProviderEducationForm);
