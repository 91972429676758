import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { ensureListing, ensureUser } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, ResponsiveImage, VerifiedUserMark } from '../../components';
import css from './ListingCard.css';

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const { className, rootClassName, listing, renderSizes, setActiveListing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '' } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const fireGtmEvent = () => {
    if (typeof window === 'object') {
      window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
          items: [
            {
              item_name: listing.attributes.title,
              item_id: listing.id.uuid,
              price: listing.attributes.publicData?.priceArray
                ? '' + listing.attributes.publicData?.priceArray[0]?._packagePrice / 100
                : '' + listing.attributes.price.amount / 100,
              item_brand: listing.author.attributes.profile.displayName,
              item_category: listing.attributes.publicData.category,
              item_list_name: 'search_results',
              quantity: '1',
            },
          ],
        },
      });
    }
  };

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div onClick={fireGtmEvent}>
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div className={css.aspectWrapper}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
            />
          </div>
        </div>
        <div className={css.info}>
          <div className={css.title}>{title}</div>
          <div className={css.mainInfo}>
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
              <VerifiedUserMark user={author} />
            </div>
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
