import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ProfileSettingsContainer as Container,
  InlineTextButton,
  Button,
  Tooltip,
} from '../../components';
import { MdVerified } from 'react-icons/md';

import css from './Container.css';

const WorkExperienceContainer = ({
  profileUser,
  button,
  handleRequestTestimonial,
  offPlatformTestimonials,
}) => {
  const [toggleRestOfWorkedWith, setToggleRestOfWorkedWith] = useState(false);

  const workExperience = profileUser?.attributes?.profile?.publicData?.workExperience;

  const workExperienceWithTestimonials = workExperience?.map(e => {
    const testimonial = offPlatformTestimonials?.find(r => r.workExperienceId === e.id);
    return { ...e, testimonial };
  });

  const formOfEmploymentFreelancer = (
    <FormattedMessage id="ProfilePage.formOfEmploymentFreelancer" />
  );
  const formOfEmploymentEmployee = <FormattedMessage id="ProfilePage.formOfEmploymentEmployee" />;

  const showToggleWorkedWithButton = workExperience?.length > 3;

  const sortedWorkExperience = workExperienceWithTestimonials?.sort((a, b) => {
    // Handle ongoing jobs (jobContinues === true)
    if (a.jobContinues && b.jobContinues) {
      // Sort ongoing jobs by startYear (descending order)
      if (a.startYear !== b.startYear) {
        return b.startYear - a.startYear;
      }
      // If startYear is the same, compare by startMonth (descending order)
      return b.startMonth - a.startMonth;
    }

    // If only one job is ongoing, it should appear first
    if (a.jobContinues) return -1;
    if (b.jobContinues) return 1;

    // For completed jobs, compare by endYear first (descending order)
    if (a.endYear !== b.endYear) {
      return b.endYear - a.endYear;
    }

    // If endYear is the same, compare by endMonth (descending order)
    return b.endMonth - a.endMonth;
  });

  const workExperienceToShow = toggleRestOfWorkedWith
    ? sortedWorkExperience
    : sortedWorkExperience?.slice(0, 3);

  const formOfEmployment = form => {
    if (form === 'freelancer') {
      return formOfEmploymentFreelancer;
    } else if (form === 'employee') {
      return formOfEmploymentEmployee;
    }
  };

  const TestimonialContainer = ({ e, testimonial }) => {
    if (testimonial?.state === 'requested' && handleRequestTestimonial) {
      return (
        <div className={css.testimonialState}>
          <FormattedMessage id="ProfilePage.workExperienceTestimonialRequested" />
        </div>
      );
    }
    if (testimonial?.state === 'reviewed' && handleRequestTestimonial) {
      return (
        <div className={css.testimonialState}>
          <FormattedMessage id="ProfilePage.workExperienceTestimonialReviewed" />
        </div>
      );
    }
    if (testimonial?.state === 'published') {
      return (
        <div className={css.workExperienceReview}>
          <div className={css.reviewHeading}>
            <FormattedMessage id="ProfilePage.workExperienceTestimonialTitle" />
            <Tooltip messageId="ProfilePage.workExperienceTestimonialInfoText">
              <span>
                <MdVerified className={css.icon} />
              </span>
            </Tooltip>
          </div>
          <div className={css.text}>
            {testimonial?.authorName}, {testimonial?.authorPosition}
          </div>
          <div className={css.contentText}>"{testimonial?.content}"</div>
        </div>
      );
    }
    return (
      <div className={css.workExperienceReview}>
        {handleRequestTestimonial && !testimonial ? (
          <Button
            className={css.testimonialButton}
            onClick={event => {
              event.preventDefault();
              handleRequestTestimonial(e);
            }}
          >
            <FormattedMessage id="ProfilePage.requestWorkExperienceTestimonial" />
          </Button>
        ) : null}
      </div>
    );
  };

  const container = (
    <Container heading={<FormattedMessage id="ProfilePage.workExperience" />} button={button}>
      {workExperience &&
        workExperience[0]?.startYear !== null &&
        workExperienceToShow
          .sort((a, b) => {
            // Handle ongoing jobs (jobContinues === true)
            if (a.jobContinues && b.jobContinues) {
              // Sort ongoing jobs by startYear (descending order)
              if (a.startYear !== b.startYear) {
                return b.startYear - a.startYear;
              }
              // If startYear is the same, compare by startMonth (descending order)
              return b.startMonth - a.startMonth;
            }

            // If only one job is ongoing, it should appear first
            if (a.jobContinues) return -1;
            if (b.jobContinues) return 1;

            // For completed jobs, compare by endYear first (descending order)
            if (a.endYear !== b.endYear) {
              return b.endYear - a.endYear;
            }

            // If endYear is the same, compare by endMonth (descending order)
            return b.endMonth - a.endMonth;
          })
          .map(e => (
            <div key={e.task} className={css.workExperienceContainer}>
              <div className={css.workExperience}>
                <div className={css.headingContainer}>
                  <div className={css.heading}>
                    <div className={css.customerName}>{e.customerName}</div>
                  </div>
                  <div className={css.durationContainer}>
                    <div className={css.formOfEmploymentText}>
                      {formOfEmployment(e?.formOfEmployment)}
                    </div>

                    <div className={css.durationText}>
                      {e.startMonth}/{e.startYear}
                      {e.jobContinues ? (
                        <FormattedMessage id="ProfilePage.workExperienceDurationOngoing" />
                      ) : (
                        ` - ${e.endMonth}/${e.endYear}`
                      )}
                    </div>
                  </div>
                  <div className={css.task}>{e.task}</div>
                </div>
                <div className={css.text}>{e.description}</div>
              </div>
              <TestimonialContainer e={e} testimonial={e.testimonial} />
            </div>
          ))}
      {showToggleWorkedWithButton && (
        <InlineTextButton
          type="button"
          onClick={() => setToggleRestOfWorkedWith(!toggleRestOfWorkedWith)}
        >
          {toggleRestOfWorkedWith ? (
            <FormattedMessage id="ProfilePage.less" />
          ) : (
            <FormattedMessage id="ProfilePage.more" />
          )}
        </InlineTextButton>
      )}
    </Container>
  );
  if (!button) {
    if (
      !workExperience?.length ||
      (workExperience?.length === 0 && Object.keys(workExperience[0]).length === 0)
    )
      return null;
  }
  return container;
};

export default WorkExperienceContainer;
