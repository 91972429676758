import React from 'react';

import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SolutionPages.css';

import quoteImg1 from '../../assets/systam-success-story-quote2.webp';
import quoteImg2 from '../../assets/fundis-success-story-quote.webp';
import quoteImg3 from '../../assets/eerikkila-success-story-quote.webp';
import quoteImg4 from '../../assets/corego-success-story-quote.png';

const SolutionPageCustomerReviewsComponent = props => {
  const { atLandingPage } = props;
  const reviews = [
    atLandingPage
      ? {
          text: 'SolutionPageCustomerReviews.reviewText4',
          name: 'SolutionPageCustomerReviews.reviewName4',
          title: 'SolutionPageCustomerReviews.reviewTitle4',
          img: quoteImg4,
        }
      : {
          text: 'SolutionPageCustomerReviews.reviewText1',
          name: 'SolutionPageCustomerReviews.reviewName1',
          title: 'SolutionPageCustomerReviews.reviewTitle1',
          img: quoteImg1,
        },
    {
      text: 'SolutionPageCustomerReviews.reviewText2',
      name: 'SolutionPageCustomerReviews.reviewName2',
      title: 'SolutionPageCustomerReviews.reviewTitle2',
      img: quoteImg2,
    },
    {
      text: 'SolutionPageCustomerReviews.reviewText3',
      name: 'SolutionPageCustomerReviews.reviewName3',
      title: 'SolutionPageCustomerReviews.reviewTitle3',
      img: quoteImg3,
    },
  ];

  const ReviewCards = ({ reviews }) => {
    return (
      <div className={css.sectionReviewsContainer}>
        {reviews.map(review => (
          <div className={css.reviewCard} key={review.text}>
            <div className={css.reviewCardDescription}>
              <FormattedMessage id={review.text} />
            </div>
            <div className={css.reviewCardBottomContainer}>
              <div className={css.reviewCardImageContainer}>
                <img className={css.reviewCardImage} src={review.img} alt={review.name} />
              </div>
              <div className={css.fromContainer}>
                <div className={css.reviewCardName}>
                  <FormattedMessage id={review.name} />
                </div>
                <div className={css.reviewCardTitle}>
                  <FormattedMessage id={review.title} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={atLandingPage ? css.sectionReviewsContentLandingPage : css.sectionReviewsContent}
    >
      <div className={css.sectionReviewsTitle}>
        <FormattedMessage id="SolutionPageCustomerReviews.sectionReviewsTitle" />
      </div>
      <ReviewCards reviews={reviews} />
      <div className={css.sectionReviewsBottomRow}>
        <NamedLink name="SuccessStoriesPage" className={css.sectionReviewsCTA}>
          <FormattedMessage id="SolutionPageCustomerReviews.heroCTA" />
        </NamedLink>
      </div>
    </div>
  );
};

const SolutionPageCustomerReviews = compose(
  withViewport,
  injectIntl
)(SolutionPageCustomerReviewsComponent);

export default SolutionPageCustomerReviews;
