import React from 'react';
import { injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';

import { ProfileSettingsContainer as Container } from '../../components';
import css from './Container.css';

const LinksContainerComponent = ({ profileUser, button, intl, isValidProvider }) => {
  const linksHeading = intl.formatMessage({ id: 'ProfileSettingsForm.linksHeading' });
  const websiteHeading = intl.formatMessage({ id: 'ProfileSettingsForm.websiteHeading' });
  const linkedInHeading = intl.formatMessage({ id: 'ProfileSettingsForm.linkedInHeading' });

  const website = profileUser?.attributes?.profile?.protectedData?.website || '';
  const linkedIn = profileUser?.attributes?.profile?.protectedData?.linkedIn || '';

  const container = (
    <Container heading={' '} button={button}>
      <div>
        {isValidProvider ? (
          <div>
            <div className={css.sectionTitle}>{linksHeading}</div>
          </div>
        ) : null}

        <div className={css.sectionTitle}>{websiteHeading}</div>
        <div className={css.whoAmI}>{website}</div>
        <div className={css.sectionTitle}>{linkedInHeading}</div>
        <div className={css.whoAmI}>{linkedIn}</div>
      </div>
    </Container>
  );

  if (!button) {
    return null;
  }
  return container;
};

const LinksContainer = compose(injectIntl)(LinksContainerComponent);

export default LinksContainer;
