import React from 'react';
import { FormattedMessage } from 'react-intl';
import ExternalLink from '../ExternalLink/ExternalLink';

import infoImage from '../../assets/start_selling_page_freelancer.webp';

import css from './SectionExpandingTo.css';

const SectionExpandingTo = () => {
  const title = <FormattedMessage id="SectionExpandingTo.title" />;
  const description = <FormattedMessage id="SectionExpandingTo.description" />;
  const description2 = <FormattedMessage id="SectionExpandingTo.description2" />;

  const handleShowSpaceHome = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  return (
    <div className={css.container}>
      <div className={css.content}>
        <div className={css.title}>{title}</div>
        <div className={css.description}>{description}</div>
        <div className={css.description}>{description2}</div>
        <ExternalLink className={css.button} onClick={handleShowSpaceHome}>
          <FormattedMessage id="ProLandingPage.heroContactButton" />
        </ExternalLink>
      </div>
      <div className={css.imgContainer}>
        <img src={infoImage} alt="infoImage" />
      </div>
    </div>
  );
};

export default SectionExpandingTo;
