import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconEnquiry } from '../../components';
import { propTypes } from '../../util/types';

import css from './RequestWorkExperienceTestimonialForm.css';

const RequestWorkExperienceTestimonialFormComponent = props => {
  return (
    <FinalForm
      {...props}
      mutators={{ setFieldData }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          intl,
          values,
          workExperience,
        } = fieldRenderProps;

        const emailLabel = intl.formatMessage({
          id: 'RequestWorkExperienceTestimonialForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'RequestWorkExperienceTestimonialForm.emailPlaceholder',
        });

        const authorNameLabel = intl.formatMessage({
          id: 'RequestWorkExperienceTestimonialForm.authorNameLabel',
        });
        const authorNamePlaceholder = intl.formatMessage({
          id: 'RequestWorkExperienceTestimonialForm.authorNamePlaceholder',
        });

        const requestInstructions1 = intl.formatMessage({
          id: 'RequestWorkExperienceTestimonialForm.requestInstructions1',
        });
        const requestInstructions2 = intl.formatMessage({
          id: 'RequestWorkExperienceTestimonialForm.requestInstructions2',
        });
        const requestInstructions3 = intl.formatMessage({
          id: 'RequestWorkExperienceTestimonialForm.requestInstructions3',
        });

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <IconEnquiry className={css.icon} />

            <div>
              <h2 className={css.heading}>
                <FormattedMessage
                  id="RequestWorkExperienceTestimonialForm.heading"
                  values={{
                    task: workExperience?.task,
                    customerName: workExperience?.customerName,
                    b: chunks => <strong style={{ fontWeight: 600 }}>{chunks}</strong>,
                  }}
                />
              </h2>
              <article className={css.instructions}>
                <p>{requestInstructions1}</p>
                <p>{requestInstructions2}</p>
                <p>{requestInstructions3}</p>
              </article>
              <FieldTextInput
                className={css.field}
                type="textarea"
                name="authorEmail"
                id={formId ? `${formId}.authorEmail` : 'authorEmail'}
                label={emailLabel}
                placeholder={emailPlaceholder}
              />
              <FieldTextInput
                className={css.field}
                type="textarea"
                name="authorName"
                id={formId ? `${formId}.authorName` : 'authorName'}
                label={authorNameLabel}
                placeholder={authorNamePlaceholder}
              />
              <div className={css.submitButtonContainer}>
                <PrimaryButton
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled || !values.authorEmail || !values.authorName}
                >
                  <FormattedMessage id="RequestWorkExperienceTestimonialForm.submitButtonText" />
                </PrimaryButton>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

RequestWorkExperienceTestimonialFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

RequestWorkExperienceTestimonialFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const RequestWorkExperienceTestimonialForm = compose(injectIntl)(
  RequestWorkExperienceTestimonialFormComponent
);

RequestWorkExperienceTestimonialForm.displayName = 'RequestWorkExperienceTestimonialForm';

export default RequestWorkExperienceTestimonialForm;
