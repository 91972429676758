import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
} from '../../components';
import { TaxInformationForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { saveTaxInformation, saveContactDetailsClear } from './TaxInformationPage.duck';
import css from './TaxInformationPage.css';

export const TaxInformationPageComponent = props => {
  const {
    currentUser,
    onChange,
    scrollingDisabled,
    onTaxInformationSubmit,
    intl,
    saveTaxInformationInProgress,
    taxInformationChanged,
    taxInformationSuccess,
    taxInformationError,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const privateData = user.attributes.profile.privateData || {};
  const companyName = privateData?.companyName;
  const address = privateData?.address;
  const city = privateData?.city;
  const postalCode = privateData?.postalCode;
  const taxIdentificationNumber = privateData?.taxIdentificationNumber;
  const tinCountry = privateData?.tinCountry;
  const businessRegistrationNumber = privateData?.businessRegistrationNumber;
  const businessRegistrationCountryOfIssue = privateData?.businessRegistrationCountryOfIssue;
  const vat_number = user.attributes.profile.protectedData?.vat_number;
  const dateOfBirth = privateData?.dateOfBirth;
  const birthLocation = privateData?.birthLocation;

  const contactInfoForm = user.id ? (
    <TaxInformationForm
      className={css.form}
      initialValues={{
        companyName,
        address,
        city,
        postalCode,
        taxIdentificationNumber,
        tinCountry,
        businessRegistrationNumber,
        businessRegistrationCountryOfIssue,
        vat_number,
        dateOfBirth,
        birthLocation,
      }}
      currentUser={currentUser}
      onSubmit={values =>
        onTaxInformationSubmit(
          {
            ...values,
          },
          currentUser
        )
      }
      onChange={onChange}
      inProgress={saveTaxInformationInProgress}
      taxInformationChanged={taxInformationChanged}
      taxInformationSuccess={taxInformationSuccess}
      taxInformationError={taxInformationError}
    />
  ) : null;

  const title = intl.formatMessage({ id: 'TaxInformationPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="TaxInformationPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav
          currentTab="TaxInformationPage"
          currentUser={currentUser}
        />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="TaxInformationPage.heading" />
            </h1>
            {contactInfoForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

TaxInformationPageComponent.defaultProps = {
  currentUser: null,
};

const { bool, func } = PropTypes;

TaxInformationPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  onChange: func.isRequired,
  onTaxInformationSubmit: func.isRequired,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  const {
    saveTaxInformationInProgress,
    taxInformationError,
    taxInformationSuccess,
  } = state.TaxInformationPage;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    saveTaxInformationInProgress,
    taxInformationError,
    taxInformationSuccess,
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(saveContactDetailsClear()),
  onTaxInformationSubmit: (values, currentUser) =>
    dispatch(saveTaxInformation(values, currentUser)),
});

const TaxInformationPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TaxInformationPageComponent);

TaxInformationPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default TaxInformationPage;
