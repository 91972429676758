import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, VerifiedUserMark } from '../../components';

import css from './ProjectListingPage.css';

const SectionHeading = props => {
  const { richTitle, hostLink, showContactUser, user, onContactUser } = props;

  return (
    <>
      <div className={css.sectionHeading}>
        <div className={css.heading}>
          <h1 className={css.title}>{richTitle}</h1>
          <div className={css.author}>
            <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
            <VerifiedUserMark user={user} />
            {showContactUser ? (
              <span className={css.contactWrapper}>
                <span className={css.separator}>•</span>
                <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                  <FormattedMessage id="ListingPage.contactUser" />
                </InlineTextButton>
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(withRouter)(SectionHeading);
