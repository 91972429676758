import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { ListingUserCard, PaginationLinks, AdvertiseProjectsCard } from '../../components';
import { updateFavoriteListings } from '../../ducks/user.duck';
import { setInitialValues } from '../../containers/ListingPage/ListingPage.duck';
import css from './SearchResultsPanel.css';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    routeName,
    params,
    history,
    location,
    currentUser,
    onupdateFavoriteListings,
    favoriteListings,
    callSetInitialValues,
    currentPageReviews,
    onSetSignUpFrom,
    onSetSignUpFromPath,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={routeName === undefined ? 'SearchPage' : routeName}
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) 100vw`,
    `(max-width: 1920px) 100vw`,
    `100vw`,
  ].join(', ');

  const onContactUser = (openModal = true) => {
    // const { currentUser, history, callSetInitialValues, params, location } = props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      openModal &&
        callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true, showHiringSteps: false });
    }
  };

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {listings.map((l, ind) => {
          const currentAuthorReviews = currentPageReviews?.filter(user => {
            const currentReviewUserId = user?.relationships?.subject?.data?.id?.uuid;
            const authorId = l?.author?.id?.uuid;
            return currentReviewUserId === authorId;
          });
          return (
            <ListingUserCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={setActiveListing}
              reviews={currentAuthorReviews}
              updateFavoriteListings={onupdateFavoriteListings}
              favoriteListings={favoriteListings}
              currentUser={currentUser}
              onContactUser={onContactUser}
            />
          );
        })}
        {props.children}
      </div>
      {paginationLinks}
      <AdvertiseProjectsCard
        currentUser={currentUser}
        onSetSignUpFrom={onSetSignUpFrom}
        onSetSignUpFromPath={onSetSignUpFromPath}
        history={history}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    favoriteListings: state.user.favoriteListingsIds,
  };
};

const mapDispatchToProps = dispatch => ({
  onupdateFavoriteListings: params => dispatch(updateFavoriteListings(params)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
});

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default compose(connect(mapStateToProps, mapDispatchToProps)(SearchResultsPanel));
