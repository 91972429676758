import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { NamedLink } from '..';

import { compose } from 'redux';

import penPaperIcon from '../../assets/pen-an-paper-icon.svg';
import websiteIcon from '../../assets/website-icon.svg';
import graphicDesignIcon from '../../assets/graphic-design-icon.svg';
import digitalMarketingIcon from '../../assets/digital-marketing-icon.svg';
import cameraLensIcon from '../../assets/camera-lens-icon.svg';
import programmingIcon from '../../assets/programming-icon.svg';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import logoAdobe from '../../assets/categories-logo-adobe.svg';
import logoFigma from '../../assets/categories-logo-figma.svg';
import logoGoogleAnalytics from '../../assets/categories-logo-google-analytics.svg';
import logoHubspot from '../../assets/categories-logo-hubspot.svg';
import logoMonday from '../../assets/categories-logo-monday.svg';
import logoSalesforce from '../../assets/categories-logo-salesforce.svg';
import logoSemrush from '../../assets/categories-logo-semrush.svg';
import logoShopify from '../../assets/categories-logo-shopify.svg';
import logoWebflow from '../../assets/categories-logo-webflow.svg';

import css from './SectionLocations.css';

const SectionLocationsComponent = props => {
  const [showSubCategories, setShowSubCategories] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const subCategories = [
    {
      category: 'SectionLocations.category1',
      subCategories: [
        'SectionLocations.subCategory1',
        'SectionLocations.subCategory2',
        'SectionLocations.subCategory3',
        'SectionLocations.subCategory4',
        'SectionLocations.subCategory5',
        'SectionLocations.subCategory6',
        'SectionLocations.subCategory7',
        'SectionLocations.subCategoryAndMore',
      ],
    },
    {
      category: 'SectionLocations.category2',
      subCategories: [
        'SectionLocations.subCategory9',
        'SectionLocations.subCategory10',
        'SectionLocations.subCategory11',
        'SectionLocations.subCategory12',
        'SectionLocations.subCategory13',
        'SectionLocations.subCategory14',
        'SectionLocations.subCategoryAndMore',
      ],
    },
    {
      category: 'SectionLocations.category3',
      subCategories: [
        'SectionLocations.subCategory15',
        'SectionLocations.subCategory16',
        'SectionLocations.subCategory17',
        'SectionLocations.subCategory18',
        'SectionLocations.subCategory19',
        'SectionLocations.subCategory20',
        'SectionLocations.subCategory21',
        'SectionLocations.subCategoryAndMore',
      ],
    },
    {
      category: 'SectionLocations.category4',
      subCategories: [
        'SectionLocations.subCategory22',
        'SectionLocations.subCategory23',
        'SectionLocations.subCategory24',
        'SectionLocations.subCategory25',
        'SectionLocations.subCategory26',
        'SectionLocations.subCategory27',
        'SectionLocations.subCategory28',
        'SectionLocations.subCategoryAndMore',
      ],
    },
    {
      category: 'SectionLocations.category5',
      subCategories: [
        'SectionLocations.subCategory29',
        'SectionLocations.subCategory30',
        'SectionLocations.subCategory31',
        'SectionLocations.subCategory32',
        'SectionLocations.subCategory34',
        'SectionLocations.subCategory35',
        'SectionLocations.subCategoryAndMore',
      ],
    },
    {
      category: 'SectionLocations.category6',
      subCategories: [
        'SectionLocations.subCategory36',
        'SectionLocations.subCategory37',
        'SectionLocations.subCategory38',
        'SectionLocations.subCategory39',
        'SectionLocations.subCategory40',
        'SectionLocations.subCategoryAndMore',
      ],
    },
  ];

  const CategoryElement = ({ icon, category, index, showSubCategories }) => {
    return (
      <div
        className={css.categoryContainer}
        onClick={() => {
          const newArray = showSubCategories.map((obj, i) => (i === index ? !obj : false));
          setShowSubCategories([...newArray]);
        }}
      >
        <div className={showSubCategories[index] ? css.categoryShowSubCategories : css.category}>
          <div>
            <img src={icon} alt="icon" className={css.categoryIcon} />
            <FormattedMessage id={category} />
          </div>
          {showSubCategories[index] ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
        <div className={showSubCategories[index] ? css.subCategories : css.hideSubCategories}>
          {subCategories[index].subCategories.map(subCategory => (
            <div key={subCategory} className={css.subCategory}>
              <FormattedMessage id={subCategory} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={css.container}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.caption}>
        <FormattedMessage id="SectionLocations.caption" />
      </div>
      <div className={css.categoriesContainer}>
        <div className={css.categories}>
          <CategoryElement
            icon={digitalMarketingIcon}
            category="SectionLocations.category1"
            index={0}
            showSubCategories={showSubCategories}
          />
          <CategoryElement
            icon={graphicDesignIcon}
            category="SectionLocations.category3"
            index={2}
            showSubCategories={showSubCategories}
          />
          <CategoryElement
            icon={websiteIcon}
            category="SectionLocations.category4"
            index={3}
            showSubCategories={showSubCategories}
          />
        </div>

        <div className={css.categories}>
          <CategoryElement
            icon={penPaperIcon}
            category="SectionLocations.category2"
            index={1}
            showSubCategories={showSubCategories}
          />
          <CategoryElement
            icon={cameraLensIcon}
            category="SectionLocations.category5"
            index={4}
            showSubCategories={showSubCategories}
          />
          <CategoryElement
            icon={programmingIcon}
            category="SectionLocations.category6"
            index={5}
            showSubCategories={showSubCategories}
          />
        </div>
      </div>
      <div className={css.logosContainer}>
        <div className={css.logosTitle}>
          <FormattedMessage id="SectionLocations.logosTitle" />
        </div>
        <div className={css.logos}>
          <img src={logoHubspot} alt="logo" className={css.logo} />
          <img src={logoShopify} alt="logo" className={css.logo} />
          <img src={logoFigma} alt="logo" className={css.logo} />
          <img src={logoWebflow} alt="logo" className={css.logo} />
          <img src={logoGoogleAnalytics} alt="logo" className={css.logo} />
          <img src={logoSalesforce} alt="logo" className={css.logo} />
          <img src={logoAdobe} alt="logo" className={css.logo} />
          <img src={logoMonday} alt="logo" className={css.logo} />
          <img src={logoSemrush} alt="logo" className={css.logo} />
        </div>
        <div className={css.logosCaption}>
          <FormattedMessage id="SectionLocations.logosCaption" />
        </div>
      </div>
      <div className={css.ctaContainer}>
        <NamedLink name="SignupPage" className={css.sectionLocationsButton}>
          <FormattedMessage id="SectionLocations.buttonText" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionLocationsComponent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocationsComponent.propTypes = {
  rootClassName: string,
  className: string,
};

const SectionLocations = compose(injectIntl)(SectionLocationsComponent);

export default SectionLocations;
