import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { leaveOffPlatformReview, fetchProfileUserName } from '../../util/api';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const FETCH_USER_FULL_NAME_REQUEST = 'app/ProfilePage/FETCH_USER_FULL_NAME_REQUEST';
export const FETCH_USER_FULL_NAME_SUCCESS = 'app/ProfilePage/FETCH_USER_FULL_NAME_SUCCESS';
export const FETCH_USER_FULL_NAME_ERROR = 'app/ProfilePage/FETCH_USER_FULL_NAME_ERROR';

export const QUERY_CURRENT_OFF_PLATFORM_REVIEW_REQUEST =
  'app/ProfilePage/QUERY_CURRENT_OFF_PLATFORM_REVIEW_REQUEST';
export const QUERY_CURRENT_OFF_PLATFORM_REVIEW_SUCCESS =
  'app/ProfilePage/QUERY_CURRENT_OFF_PLATFORM_REVIEW_SUCCESS';
export const QUERY_CURRENT_OFF_PLATFORM_REVIEW_ERROR =
  'app/ProfilePage/QUERY_CURRENT_OFF_PLATFORM_REVIEW_ERROR';

export const LEAVE_OFF_PLATFORM_REVIEW_REQUEST =
  'app/ProfilePage/LEAVE_OFF_PLATFORM_REVIEW_REQUEST';
export const LEAVE_OFF_PLATFORM_REVIEW_SUCCESS =
  'app/ProfilePage/LEAVE_OFF_PLATFORM_REVIEW_SUCCESS';
export const LEAVE_OFF_PLATFORM_REVIEW_ERROR = 'app/ProfilePage/LEAVE_OFF_PLATFORM_REVIEW_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userShowError: null,
  currentOffPlatformReview: null,
  querycurrentOffPlatformReviewError: null,
  leaveOffPlatformReviewError: null,
  userFullName: null,
  userFullNameError: null,
};

export default function offPlatfromReviewReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case FETCH_USER_FULL_NAME_REQUEST:
      return { ...state, userFullNameError: null };
    case FETCH_USER_FULL_NAME_SUCCESS:
      return { ...state, userFullName: payload };
    case FETCH_USER_FULL_NAME_ERROR:
      return { ...state, userFullNameError: payload };

    case LEAVE_OFF_PLATFORM_REVIEW_REQUEST:
      return { ...state, leaveOffPlatformReviewError: null };
    case LEAVE_OFF_PLATFORM_REVIEW_SUCCESS:
      return state;
    case LEAVE_OFF_PLATFORM_REVIEW_ERROR:
      return { ...state, leaveOffPlatformReviewError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const fetchUserFullNameRequest = () => ({
  type: FETCH_USER_FULL_NAME_REQUEST,
});

export const fetchUserFullNameSuccess = userFullName => ({
  type: FETCH_USER_FULL_NAME_SUCCESS,
  payload: userFullName,
});

export const fetchUserFullNameError = e => ({
  type: FETCH_USER_FULL_NAME_ERROR,
  error: true,
  payload: e,
});

export const querycurrentOffPlatformReviewRequest = () => ({
  type: QUERY_CURRENT_OFF_PLATFORM_REVIEW_REQUEST,
});

export const querycurrentOffPlatformReviewSuccess = review => ({
  type: QUERY_CURRENT_OFF_PLATFORM_REVIEW_SUCCESS,
  payload: review,
});

export const querycurrentOffPlatformReviewError = e => ({
  type: QUERY_CURRENT_OFF_PLATFORM_REVIEW_ERROR,
  error: true,
  payload: e,
});

export const leaveOffPlatformReviewRequest = () => ({
  type: LEAVE_OFF_PLATFORM_REVIEW_REQUEST,
});

export const leaveOffPlatformReviewSuccess = () => ({
  type: LEAVE_OFF_PLATFORM_REVIEW_SUCCESS,
});

export const leaveOffPlatformReviewError = e => ({
  type: LEAVE_OFF_PLATFORM_REVIEW_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const getUserFullName = userId => (dispatch, getState, sdk) => {
  if (typeof window !== 'undefined') {
    dispatch(fetchUserFullNameRequest());
    return fetchProfileUserName(userId.uuid)
      .then(response => {
        dispatch(fetchUserFullNameSuccess(response));
      })
      .catch(e => dispatch(fetchUserFullNameError(e)));
  }
};

export const postOffPlatformReview = (freelancerId, reviewRating, content, authorName) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(leaveOffPlatformReviewRequest());
  const body = { freelancerId, reviewRating, content, authorName };
  const reviewResponse = leaveOffPlatformReview(body);
  Promise.resolve(reviewResponse)
    .then(response => {
      dispatch(querycurrentOffPlatformReviewSuccess(response));
      dispatch(leaveOffPlatformReviewSuccess());
    })
    .catch(e => dispatch(leaveOffPlatformReviewError(storableError(e))));
};

export const loadData = userId => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([dispatch(showUser(userId)), dispatch(getUserFullName(userId))]);
};
