import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';

import review5Stars from '../../assets/review-5-stars.svg';

import imgSofiaK from '../../assets/review-image-sofiak.png';
import imgMattiS from '../../assets/review-image-mattis.png';
import imgHelmiS from '../../assets/review-image-helmis.png';
import imgSannaU from '../../assets/review-image-sannau.png';
import imgJohannaK from '../../assets/review-image-johannak.png';
import imgAnniinaL from '../../assets/review-image-anniinal.png';

import css from './SectionReviews.css';

const SectionReviews = () => {
  const [currentMobileReview, setCurrentMobileReview] = useState(0);

  const reviews = [
    {
      from: 'Milla S',
      text: <FormattedMessage id="SectionReviews.reviewSofiaK" />,
      image: imgSofiaK,
      name: 'Sofia K',
      title: <FormattedMessage id="SectionReviews.titleSofiaK" />,
      id: '63722368-d8bc-4748-b395-c8e085f26e37',
    },
    {
      from: 'Atte S',
      text: <FormattedMessage id="SectionReviews.reviewMattiS" />,
      image: imgMattiS,
      name: 'Matti S',
      title: <FormattedMessage id="SectionReviews.titleMattiS" />,
      id: '61e5b063-4437-4d86-98ad-f41c006aea87',
    },
    {
      from: 'Joonas L',
      text: <FormattedMessage id="SectionReviews.reviewHelmiS" />,
      image: imgHelmiS,
      name: 'Helmi S',
      title: <FormattedMessage id="SectionReviews.titleHelmiS" />,
      id: '64e1c0a6-d25b-4f9a-875d-0efd828d46e4',
    },
    {
      from: 'Mikko M',
      text: <FormattedMessage id="SectionReviews.reviewSannaU" />,
      image: imgSannaU,
      name: 'Sanna U',
      title: <FormattedMessage id="SectionReviews.titleSannaU" />,
      id: '624a82a5-233e-4249-acb3-739b734bcdc6',
    },
    {
      from: 'Roni K',
      text: <FormattedMessage id="SectionReviews.reviewJohannaK" />,
      image: imgJohannaK,
      name: 'Johanna K',
      title: <FormattedMessage id="SectionReviews.titleJohannaK" />,
      id: '61cf61be-feea-4bd3-aa81-28f1b5f6a418',
    },
    {
      from: 'Milla S',
      text: <FormattedMessage id="SectionReviews.reviewAnniinaL" />,
      image: imgAnniinaL,
      name: 'Anniina L',
      title: <FormattedMessage id="SectionReviews.titleAnniinaL" />,
      id: '61ee6c5b-e622-4485-bfdd-83702783d1a4',
    },
  ];

  const ReviewCard = ({ from, text, image, name, title, id }) => {
    return (
      <div className={css.reviewCard}>
        <div className={css.reviewCardReviewContainer}>
          <div className={css.reviewCardFrom}>{from}</div>
          <div className={css.reviewStars}>
            <img src={review5Stars} alt="5 stars" />
          </div>
          <div className={css.reviewCardText}>{text}</div>
        </div>

        <div className={css.reviewCardFreelancerContainer}>
          <div className={css.reviewCardFreelancerImage}>
            <img src={image} alt={name} />
          </div>
          <div className={css.reviewCardFreelancerInfo}>
            <div className={css.reviewCardFreelancerName}>{name}</div>
            <div className={css.reviewCardFreelancerTitle}>{title}</div>
          </div>
          {/* <NamedLink className={css.toProfileCTA} name="ProfilePage" params={{ id: id }}>
            <FormattedMessage id="SectionReviews.ctaText" />
          </NamedLink> */}
        </div>
      </div>
    );
  };

  const prevReview = () => {
    if (currentMobileReview === reviews.length - 1) {
      setCurrentMobileReview(0);
    } else {
      setCurrentMobileReview(currentMobileReview + 1);
    }
  };

  const nextReview = () => {
    if (currentMobileReview === 0) {
      setCurrentMobileReview(reviews.length - 1);
    } else {
      setCurrentMobileReview(currentMobileReview - 1);
    }
  };

  return (
    <div className={css.container}>
      <div className={css.title}>
        <FormattedMessage id="SectionReviews.title" />
      </div>
      <div className={css.contentDesktop}>
        {reviews.map((review, index) => (
          <ReviewCard
            key={index}
            from={review.from}
            text={review.text}
            image={review.image}
            name={review.name}
            title={review.title}
            id={review.id}
          />
        ))}
      </div>
      <div className={css.contentMobile}>
        <ReviewCard
          from={reviews[currentMobileReview].from}
          text={reviews[currentMobileReview].text}
          image={reviews[currentMobileReview].image}
          name={reviews[currentMobileReview].name}
          title={reviews[currentMobileReview].title}
          id={reviews[currentMobileReview].id}
        />
        <div className={css.arrows}>
          <BsArrowLeftCircle className={css.arrow} onClick={() => prevReview()} />
          <BsArrowRightCircle className={css.arrow} onClick={() => nextReview()} />
        </div>
      </div>
    </div>
  );
};

export default SectionReviews;
