import React, { useState } from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withViewport } from '../../util/contextHelpers';
import config from '../../config';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  SectionLocations,
  FaqDropdown,
  SectionHero,
  BuyerTileMarketplace,
  BuyerTileFreedomlyPro,
  ProContactSection,
} from '../../components';
import { TopbarContainer } from '..';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { IoIosArrowDown } from 'react-icons/io';

import css from './StartBuyingInfoPage.css';
import infoImage from '../../assets/start-buying-info.webp';
import info2Image from '../../assets/start-buying-info2.png';

export const StartBuyingInfoPageComponent = props => {
  const { intl, scrollingDisabled, history, location, viewport } = props;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'StartBuyingInfoPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'StartBuyingInfoPage.schemaDescription' });

  const faqQuestion1 = <FormattedMessage id="StartBuyingInfoPage.faqQuestion1" />;
  const faqQuestion2 = <FormattedMessage id="StartBuyingInfoPage.faqQuestion2" />;
  const faqQuestion3 = <FormattedMessage id="StartBuyingInfoPage.faqQuestion3" />;
  const faqQuestion4 = <FormattedMessage id="StartBuyingInfoPage.faqQuestion4" />;
  const faqQuestion5 = <FormattedMessage id="StartBuyingInfoPage.faqQuestion5" />;

  const faqAnswer1 = (
    <div>
      <h4 className={css.faqSubheading}>
        <FormattedMessage id="StartBuyingInfoPage.faqAnswer1Subtitle1" />
      </h4>
      <ul className={css.listUnordered}>
        <li>
          <FormattedMessage id="StartBuyingInfoPage.faqAnswer1Item1" />
        </li>
        <li>
          <FormattedMessage id="StartBuyingInfoPage.faqAnswer1Item2" />
        </li>
        <li>
          <FormattedMessage id="StartBuyingInfoPage.faqAnswer1Item3" />
        </li>
        <li>
          <FormattedMessage id="StartBuyingInfoPage.faqAnswer1Item4" />
        </li>
        <li>
          <FormattedMessage id="StartBuyingInfoPage.faqAnswer1Item5" />
        </li>
      </ul>
    </div>
  );

  const faqAnswer2 = (
    <div>
      <FormattedMessage id="StartBuyingInfoPage.faqAnswer2Item1" />
      <br />
      <br />
      <FormattedMessage id="StartBuyingInfoPage.faqAnswer2Item2" />
      <br />
      <br />
      <FormattedMessage id="StartBuyingInfoPage.faqAnswer2Item3" />
    </div>
  );

  const faqAnswer3 = (
    <div>
      <FormattedMessage id="StartBuyingInfoPage.faqAnswer3Item1" />
    </div>
  );

  const faqAnswer4 = (
    <div>
      <FormattedMessage id="StartBuyingInfoPage.faqAnswer4Item1" />
      <br />
      <br />
      <FormattedMessage id="StartBuyingInfoPage.faqAnswer4Item2" />
    </div>
  );

  const faqAnswer5 = (
    <div>
      <FormattedMessage id="StartBuyingInfoPage.faqAnswer5Item1" />
      <br />
      <br />
      <FormattedMessage id="StartBuyingInfoPage.faqAnswer5Item2" />
    </div>
  );

  const [faqIsShown, setFaqIsShown] = useState([
    {
      question: faqQuestion1,
      answer: faqAnswer1,
      index: 0,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: faqQuestion2,
      answer: faqAnswer2,
      index: 1,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: faqQuestion3,
      answer: faqAnswer3,
      index: 2,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: faqQuestion4,
      answer: faqAnswer4,
      index: 3,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: faqQuestion5,
      answer: faqAnswer5,
      index: 4,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
  ]);

  const heroTitle1 = <FormattedMessage id="StartBuyingInfoPage.heroTitle" />;
  const heroTitle2 = <FormattedMessage id="StartBuyingInfoPage.heroTitle2" />;
  const heroSubtitle = intl.formatMessage({ id: 'StartBuyingInfoPage.heroSubTitle' });

  const optionBannersTitle = intl.formatMessage({
    id: 'SmallBusinessSolutionPage.optionBannersTitle',
  });

  const optionBannersSubTitle = intl.formatMessage({
    id: 'SmallBusinessSolutionPage.optionBannersSubTitle',
  });

  const buyerTileFreedomlyProTitleId = 'DashboardPage.buyerTileFreedomlyProTitle';
  const buyerTileFreedomlyProSubtitleId = 'DashboardPage.buyerTileFreedomlyProSubtitle';
  const buyerTileFreedomlyProContent1 = 'DashboardPage.buyerTileFreedomlyProContent1';
  const buyerTileFreedomlyProContent2 = 'DashboardPage.buyerTileFreedomlyProContent2';
  const buyerTileFreedomlyProContent3 = 'DashboardPage.buyerTileFreedomlyProContent3';
  const buyerTileFreedomlyProContent4 = 'DashboardPage.buyerTileFreedomlyProContent4';

  const buyerTileFreedomlyProPrimaryCTAId = 'DashboardPage.buyerTileContactCTA';
  const buyerTileFreedomlyProSecondaryCTATo = 'DashboardProPage';
  const buyerTileFreedomlyProSecondaryCTAId = 'DashboardPage.buyerTileSecondaryCTA';

  const buyerTileMarketplaceTitleId = 'DashboardPage.buyerTileFreedomlyMarketplaceTitle';
  const buyerTileMarketplacesubTitleId = 'DashboardPage.buyerTileFreedomlyMarketplaceSubtitle';
  const buyerTileMarketplaceContentTitle1Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentTitle1';
  const buyerTileMarketplaceContentTitle2Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentTitle2';
  const buyerTileMarketplaceContentTitle3Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentTitle3';
  const buyerTileMarketplaceContentText1Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentText1';
  const buyerTileMarketplaceContentText2Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentText2';
  const buyerTileMarketplaceContentText3Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentText3';

  const buyerTileMarketplacePrimaryCTATo = 'NewProjectListingPage';
  const buyerTileMarketplacePrimaryCTAId = 'SmallBusinessSolutionPage.buyerTilePostJobCTA';

  const buyerTileMarketplaceSecondaryCTATo = 'DashboardMarketplacePage';
  const buyerTileMarketplaceSecondaryCTAId = 'DashboardPage.buyerTileSecondaryCTA';

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="StartBuyingInfoPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <ul className={css.sections}>
            <li className={css.section}>
              <SectionHero
                history={history}
                location={location}
                heroTitle1={heroTitle1}
                heroTitle2={heroTitle2}
                heroSubtitle={heroSubtitle}
                atLandingPage={false}
                noButton={true}
                atProLandingPage={true}
              />
            </li>
            <li className={css.section}>
              <div className={css.startBuyingInfoSection}>
                <div className={css.startBuyingInfoContent}>
                  <div className={css.startBuyingInfoContentTitle}>
                    <FormattedMessage id="StartBuyingInfoPage.startBuyingInfoTitle" />
                  </div>
                  <div className={css.startBuyingInfoContentText}>
                    <FormattedMessage id="StartBuyingInfoPage.startBuyingInfoText" />
                    <br />
                    <br />
                    <FormattedMessage id="StartBuyingInfoPage.startBuyingInfoText2" />
                  </div>
                </div>
                <div className={css.startBuyingInfoImageContainer}>
                  <img src={infoImage} alt="infoImage" />
                </div>
              </div>
            </li>
            <li className={css.section}>
              <div className={css.startBuyingInfoSectionDarkBG}>
                <div className={css.startBuyingInfoImageContainer}>
                  <img src={info2Image} alt="infoImage" />
                </div>
                <div className={css.startBuyingInfoContent}>
                  <div className={css.startBuyingInfoContentTitle}>
                    <FormattedMessage id="StartBuyingInfoPage.startBuyingInfo2Title" />
                  </div>
                  <div className={css.startBuyingInfoContentText}>
                    <FormattedMessage id="StartBuyingInfoPage.startBuyingInfo2Text" />
                  </div>
                </div>
              </div>
            </li>
            <li className={css.section}>
              <div className={css.optionBannersSection}>
                <div className={css.optionBannersTitleContainer}>
                  <h2 className={css.optionBannersTitle}>{optionBannersTitle}</h2>
                  <div className={css.optionBannersSubtitle}>{optionBannersSubTitle}</div>
                </div>
                <BuyerTileFreedomlyPro
                  buyerTileFreedomlyProTitleId={buyerTileFreedomlyProTitleId}
                  buyerTileFreedomlyProSubtitleId={buyerTileFreedomlyProSubtitleId}
                  buyerTileFreedomlyProContent1={buyerTileFreedomlyProContent1}
                  buyerTileFreedomlyProContent2={buyerTileFreedomlyProContent2}
                  buyerTileFreedomlyProContent3={buyerTileFreedomlyProContent3}
                  buyerTileFreedomlyProContent4={buyerTileFreedomlyProContent4}
                  buyerTileFreedomlyProPrimaryCTAId={buyerTileFreedomlyProPrimaryCTAId}
                  buyerTileFreedomlyProSecondaryCTATo={buyerTileFreedomlyProSecondaryCTATo}
                  buyerTileFreedomlyProSecondaryCTAId={buyerTileFreedomlyProSecondaryCTAId}
                  showSecondaryCTA={false}
                />
                <BuyerTileMarketplace
                  buyerTileMarketplaceTitleId={buyerTileMarketplaceTitleId}
                  buyerTileMarketplacesubTitleId={buyerTileMarketplacesubTitleId}
                  buyerTileMarketplaceContentTitle1Id={buyerTileMarketplaceContentTitle1Id}
                  buyerTileMarketplaceContentTitle2Id={buyerTileMarketplaceContentTitle2Id}
                  buyerTileMarketplaceContentTitle3Id={buyerTileMarketplaceContentTitle3Id}
                  buyerTileMarketplaceContentText1Id={buyerTileMarketplaceContentText1Id}
                  buyerTileMarketplaceContentText2Id={buyerTileMarketplaceContentText2Id}
                  buyerTileMarketplaceContentText3Id={buyerTileMarketplaceContentText3Id}
                  buyerTileMarketplacePrimaryCTATo={buyerTileMarketplacePrimaryCTATo}
                  buyerTileMarketplacePrimaryCTAId={buyerTileMarketplacePrimaryCTAId}
                  buyerTileMarketplaceSecondaryCTATo={buyerTileMarketplaceSecondaryCTATo}
                  buyerTileMarketplaceSecondaryCTAId={buyerTileMarketplaceSecondaryCTAId}
                  showSecondaryCTA={false}
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionFAQContent}>
                <div className={css.sectionFAQTitle}>
                  <FormattedMessage id="StartBuyingInfoPage.faqSectionTitle" />
                </div>
                <FaqDropdown faqIsShown={faqIsShown} setFaqIsShown={setFaqIsShown} />
              </div>
            </li>
            <li className={css.section}>
              <SectionLocations />
            </li>
          </ul>
          <ProContactSection viewport={viewport} />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

StartBuyingInfoPageComponent.defaultProps = {
  currentUser: null,
};

StartBuyingInfoPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const StartBuyingInfoPage = compose(
  withRouter,
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(StartBuyingInfoPageComponent);

export default StartBuyingInfoPage;
