import React from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { FaSearch } from 'react-icons/fa';

import { injectIntl } from '../../util/reactIntl';
import {
  Form,
  SecondaryButton,
  Button,
  FieldSelectModern,
  IconClose,
  InlineTextButton,
} from '../../components';
import config from '../../config';
import { required } from '../../util/validators';

import css from './SignupProviderForms.css';

const skillOptions = config.custom.skillOptions;
const skillLevelOptions = config.custom.skillLevelOptions;

const Placeholder = () => (
  <div className={css.placeholderContainer}>
    <FaSearch className={css.searchIcon} />
  </div>
);

const setMainSkillMutator = (args, state, utils) => {
  const [index] = args;

  // Get current experience array from the form state
  const experience = state.formState.values.experience;

  // Determine the current 'isMainSkill' status of the selected skill
  const isCurrentlyMainSkill = experience[index].isMainSkill;

  // Count how many skills are currently main skills
  const mainSkillsCount = experience.reduce((count, item) => count + (item.isMainSkill ? 1 : 0), 0);

  // Determine the new 'isMainSkill' status
  let newIsMainSkillStatus;
  if (isCurrentlyMainSkill) {
    // If it's currently a main skill, we can always unset it
    newIsMainSkillStatus = false;
  } else {
    // If it's not currently a main skill, set it only if we haven't reached the limit of 3
    newIsMainSkillStatus = mainSkillsCount < 5;
  }

  // Update the specific experience item
  utils.changeValue(state, `experience[${index}].isMainSkill`, () => newIsMainSkillStatus);
};

const SignupProviderSkillsForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
        setMainSkillMutator,
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          onPrev,
          form,
          intl,
          form: {
            mutators: { push, setMainSkillMutator },
          },
          invalid,
          values,
          isModal,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid;

        const optionsTranslated = skillLevelOptions.map(x => ({
          ...x,
          label: intl.formatMessage({
            id: `SkillLevelOptions.${x.key}`,
          }),
        }));

        const heading = intl.formatMessage({
          id: 'SignupProviderForms.skillsHeading',
        });
        const modalHeading = intl.formatMessage({
          id: 'SignupProviderForms.modalSkillsHeading',
        });

        const nextButtonText = intl.formatMessage({
          id: 'SignupProviderForms.nextButton',
        });
        const previousButtonText = intl.formatMessage({
          id: 'SignupProviderForms.previousButton',
        });
        const addButtonText = intl.formatMessage({
          id: 'SignupProviderForms.addButton',
        });
        const okButtonText = intl.formatMessage({
          id: 'SignupProviderForms.okButton',
        });

        // const skillCategoryPlaceHolder = intl.formatMessage({
        //   id: 'SignupProviderForms.skillsSkillCategoryPlaceholder',
        // });
        const skillLevelPlaceHolder = intl.formatMessage({
          id: 'SignupProviderForms.skillsSkillLevelPlaceholder',
        });

        const point1 = intl.formatMessage({
          id: 'SignupProviderForms.skillsPoint1',
        });
        const point2 = intl.formatMessage({
          id: 'SignupProviderForms.skillsPoint2',
        });
        const point3 = intl.formatMessage({
          id: 'SignupProviderForms.skillsPoint3',
        });

        const categoryRequired = intl.formatMessage({
          id: 'SignupProviderForms.skillsCategoryRequired',
        });
        const levelRequired = intl.formatMessage({
          id: 'SignupProviderForms.skillslevelRequired',
        });

        const showAddToArray = values?.experience?.length < 20;

        // Function to filter out selected skills
        const getAvailableSkillOptions = index => {
          const selectedSkillIds = values.experience
            .map((item, idx) => idx !== index && item.category && item.category.value)
            .filter(Boolean);
          const availableSkillOptions = skillOptions.filter(
            option => !selectedSkillIds.includes(option.value)
          );

          return availableSkillOptions.map(option => ({
            ...option,
            label: intl.formatMessage({ id: option.label.props.id }),
          }));
        };

        return (
          <div className={classes}>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <h2 className={css.skillsHeading}>
                {!isModal ? heading : modalHeading}
                {isModal ? (
                  <div className={css.submitContainer}>
                    <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                      {okButtonText}
                    </Button>
                  </div>
                ) : null}
              </h2>
              <FieldArray name="experience">
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => (
                      <div key={name} className={css.skillsFieldArray}>
                        <FieldSelectModern
                          id={`${name}.category`}
                          name={`${name}.category`}
                          options={getAvailableSkillOptions(index)}
                          components={{ Placeholder }}
                          className={classNames(css.fieldArrayOption, css.skillsCategory)}
                          validate={required(categoryRequired)}
                        />
                        <FieldSelectModern
                          id={`${name}.level`}
                          name={`${name}.level`}
                          options={optionsTranslated}
                          placeholder={skillLevelPlaceHolder}
                          className={classNames(css.fieldArrayLevel, css.skillsLevel)}
                          validate={required(levelRequired)}
                        />
                        <span
                          className={css.skillsDelete}
                          onClick={() =>
                            fields?.length === 1 ? fields.update(index, {}) : fields.remove(index)
                          }
                        >
                          <IconClose rootClassName={css.deleteIcon} />
                        </span>
                      </div>
                    ))}
                  </>
                )}
              </FieldArray>
              {showAddToArray && (
                <InlineTextButton
                  type="button"
                  className={css.addToArray}
                  onClick={() => push('experience', {})}
                >
                  {addButtonText}
                </InlineTextButton>
              )}
              <ul className={css.pointerList}>
                <li>{point1}</li>
                <li>{point2}</li>
                <li>{point3}</li>
              </ul>
              <h3>
                {intl.formatMessage({
                  id: 'SignupProviderForms.skillsSelectedSkills',
                })}
              </h3>

              <div className={css.selectedSkills}>
                {values?.experience
                  ?.filter(x => x?.category?.label)
                  .map((x, index) => (
                    <div
                      key={index}
                      className={
                        x?.isMainSkill ? css.selectedSkillActive : css.selectedSkillNotActive
                      }
                      onClick={() => setMainSkillMutator(index, values, form)}
                    >
                      {x?.category?.label}
                    </div>
                  ))}
              </div>

              <div className={css.submitContainer}>
                {!isModal && (
                  <SecondaryButton rootClassName={css.backButton} onClick={onPrev}>
                    {previousButtonText}
                  </SecondaryButton>
                )}
                {!isModal ? (
                  <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                    {nextButtonText}
                  </Button>
                ) : null}
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

SignupProviderSkillsForm.defaultProps = {
  headingNeeded: false,
};

export default injectIntl(SignupProviderSkillsForm);
