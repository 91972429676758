import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  AvatarLarge,
  ProfileSettingsContainer as Container,
  Footer,
  InlineTextButton,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  ListingCard,
  NamedLink,
  Page,
  ProjectListingCard,
  Reviews,
  VerifiedUserMark,
  EducationContainer,
  LanguagesContainer,
  TopSkillsContainer,
  HobbiesContainer,
  WorkExperienceContainer,
  CodeOfConductContainer,
} from '../../components';
import { NotFoundPage, TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { withViewport } from '../../util/contextHelpers';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { REVIEW_TYPE_OF_CUSTOMER, REVIEW_TYPE_OF_PROVIDER, propTypes } from '../../util/types';
import {
  loadData,
  queryOffPlatformReviews,
  queryOffPlatformTestimonials,
} from './ProfilePage.duck';

import { FaEuroSign, FaMapMarkerAlt, FaPenAlt, FaStar } from 'react-icons/fa';
import renderHTML from 'react-render-html';
import css from './ProfilePage.css';

const { UUID } = sdkTypes;
const MAX_MOBILE_SCREEN_WIDTH = 962;

export class ProfilePageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // keep track of which reviews tab to show in desktop viewport
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
      toggleRestOfReviews: false,
    };

    this.showOfProviderReviews = this.showOfProviderReviews.bind(this);
    this.showOfCustomerReviews = this.showOfCustomerReviews.bind(this);
    this.toggleReviews = this.showRestOfReviews.bind(this);
  }

  showOfProviderReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    });
  }

  showOfCustomerReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_CUSTOMER,
    });
  }

  showRestOfReviews() {
    this.setState({
      toggleRestOfReviews: !this.state.toggleRestOfReviews,
    });
  }

  combineReviews() {
    const { reviews, offPlatformReviews } = this.props;

    // Map over reviews to extract required fields
    const formattedReviews = reviews.map(review => ({
      content: review.attributes.content,
      rating: review.attributes.rating,
      createdAt: review.attributes.createdAt,
      author: review.author,
      authorName: review.author.attributes.profile.displayName,
      abbreviatedName: review.author.attributes.profile.abbreviatedName,
      type: review.attributes.type,
      id: review.id.uuid,
      onPlatform: true,
    }));

    // Map over offPlatformReviews to extract required fields
    const formattedOffPlatformReviews = offPlatformReviews.map(offReview => ({
      content: offReview.content,
      rating: offReview.reviewRating,
      createdAt: new Date(offReview.createdAt), // Convert string to Date object
      authorName: offReview.displayName,
      abbreviatedName: offReview.abbreviatedName,
      type: REVIEW_TYPE_OF_PROVIDER,
      id: offReview._id,
      onPlatform: false,
    }));

    // Combine and return the reviews
    const allReviews = [...formattedReviews, ...formattedOffPlatformReviews];
    //Sort reviews by date
    allReviews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return allReviews;
  }

  componentDidMount() {
    const { user, onQueryOffPlatformReviews, onQueryOffPlatformTestimonials } = this.props;

    // Ensure user exists before calling the functions
    if (user && user.id) {
      onQueryOffPlatformReviews(user.id);
      onQueryOffPlatformTestimonials(user.id);
    }
  }

  render() {
    const {
      scrollingDisabled,
      currentUser,
      user,
      userShowError,
      queryListingsError,
      listings,
      reviews,
      queryReviewsError,
      offPlatformTestimonials,
      viewport,
      intl,
    } = this.props;

    const combinedReviews = this.combineReviews();

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const profileUser = ensureUser(user);
    const isCurrentUser =
      ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid;
    const displayName = profileUser?.attributes?.profile?.displayName;
    const hasBio = profileUser?.attributes?.profile?.publicData?.bio;

    const bio =
      profileUser && hasBio
        ? profileUser.attributes?.profile.publicData.bio
        : profileUser.attributes?.profile.bio
        ? profileUser.attributes?.profile?.bio
        : '';

    const accountRole = profileUser?.attributes?.profile?.publicData?.account_role;
    const noIndexing = profileUser?.attributes?.profile?.publicData?.noIndexing;

    // const fullName =
    //   !profileUserNameError && accountRole != 'customer' ? profileUserName : displayName;

    const showContainers = accountRole !== 'customer';

    const location = profileUser?.attributes?.profile?.publicData?.location;

    const topSkills = <TopSkillsContainer profileUser={profileUser} />;
    const hobbies = <HobbiesContainer profileUser={profileUser} />;
    const languages = <LanguagesContainer profileUser={profileUser} />;
    const codeOfConduct = <CodeOfConductContainer profileUser={profileUser} />;

    const hourlyFeeData = profileUser?.attributes?.profile?.publicData?.hourlyFee;
    const dontShowHourlyFee = profileUser?.attributes?.profile?.publicData?.dontShowHourlyFee;
    const hourlyFee = (
      <h2>
        <FaEuroSign className={css.euroSign} />
        {hourlyFeeData}
      </h2>
    );

    const whoAmI = profileUser?.attributes?.profile?.publicData?.whoAmI;
    const reviewsHeading = (
      <h2>
        <FaStar className={css.starIcon} />
        {combinedReviews &&
          combinedReviews?.length !== 0 &&
          (combinedReviews.reduce((a, b) => a + b?.rating, 0) / combinedReviews?.length).toFixed(1)}
        &nbsp;({combinedReviews && combinedReviews.length})
      </h2>
    );

    const asideContent = (
      <div>
        <Container>
          <div className={classNames(css.asideContent, css.container)}>
            <div className={css.bioNameContainer}>
              <AvatarLarge className={css.avatar} user={user} disableProfileLink />
              <div className={css.desktopHeading}>{displayName}</div>
              {whoAmI && <div className={css.desktopWhoamI}>{whoAmI}</div>}
            </div>

            {combinedReviews?.length > 0 && reviewsHeading}
            {user?.attributes?.profile?.metadata?.verified && (
              <h2>
                <VerifiedUserMark user={profileUser} />
                <FormattedMessage id="ProfilePage.verifiedTalent" />
              </h2>
            )}
            {location && location !== '' && location?.selectedPlace !== null && (
              <h2>
                <>
                  <FaMapMarkerAlt className={css.mapMarkerIcon} />
                  {location?.search.split(',')[0]}
                </>
              </h2>
            )}
            {hourlyFeeData && !dontShowHourlyFee && hourlyFee}
          </div>
        </Container>
        {showContainers && codeOfConduct}
        {showContainers && topSkills}
        {showContainers && hobbies}
        {showContainers && languages}
      </div>
    );

    const listingsContainerClasses = classNames(css.listingsContainer, {
      [css.withBioMissingAbove]: !hasBio,
    });

    const reviewsError = (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingReviewsFailed" />
      </p>
    );

    const reviewsOfProvider = combinedReviews?.filter(r => r?.type === REVIEW_TYPE_OF_PROVIDER);

    const reviewsOfCustomer = combinedReviews?.filter(r => r?.type === REVIEW_TYPE_OF_CUSTOMER);

    const reviewsLength =
      accountRole === 'customer' ? reviewsOfCustomer.length : reviewsOfProvider.length;

    const showToggleReviewsButton = reviewsLength > 3;

    const desktopReviews = (
      <Container>
        <div>
          <h2>
            <FormattedMessage
              id="ProfilePage.reviews"
              values={{
                count:
                  accountRole === 'customer' ? reviewsOfCustomer.length : reviewsOfProvider.length,
              }}
            />
          </h2>

          {queryReviewsError ? reviewsError : null}

          {accountRole === 'customer' ? (
            <Reviews
              reviews={
                this.state.toggleRestOfReviews ? reviewsOfCustomer : reviewsOfCustomer.slice(0, 3)
              }
            />
          ) : (
            <Reviews
              reviews={
                this.state.toggleRestOfReviews ? reviewsOfProvider : reviewsOfProvider.slice(0, 3)
              }
            />
          )}
          {showToggleReviewsButton && (
            <InlineTextButton type="button" onClick={this.toggleReviews}>
              {this.state.toggleRestOfReviews ? (
                <FormattedMessage id="ProfilePage.less" />
              ) : (
                <FormattedMessage id="ProfilePage.more" />
              )}
            </InlineTextButton>
          )}
        </div>
      </Container>
    );

    const renderListing = l =>
      l.attributes?.publicData.listingType === 'service' && !l.attributes?.publicData.hidden ? (
        <li className={css.listing} key={l.id.uuid}>
          <ListingCard listing={l} />
        </li>
      ) : l.attributes?.publicData.listingType === 'project' ? (
        <li className={css.listing} key={l.id.uuid}>
          <ProjectListingCard listing={l} />
        </li>
      ) : null;

    const workedWith = (
      <WorkExperienceContainer
        profileUser={profileUser}
        offPlatformTestimonials={offPlatformTestimonials}
      />
    );
    const educationContainer = <EducationContainer profileUser={profileUser} />;

    const hasServiceListings = listings.find(
      l =>
        l?.attributes?.publicData?.listingType === 'service' &&
        !l?.attributes?.publicData?.hidden === true
    );

    const mainContent = (
      <div className={css.mainContent}>
        <Container heading={<FormattedMessage id="ProfilePage.bio" />}>
          <div className={css.bio}>{renderHTML(bio)}</div>
        </Container>
        {combinedReviews.length > 0 && desktopReviews}
        {viewport.width <= MAX_MOBILE_SCREEN_WIDTH && showContainers && languages}
        {/* {showContainers && myClients} */}
        {showContainers && workedWith}
        {/* {showContainers && myClients} */}
        {showContainers && educationContainer}
        {hasServiceListings ? (
          <div className={listingsContainerClasses}>
            <h2 className={css.listingsTitle}>
              <FormattedMessage
                id="ProfilePage.listingsTitle"
                values={{ user: profileUser.attributes?.profile?.displayName }}
              />
            </h2>
            <ul className={css.listings}>{listings.map(l => renderListing(l))}</ul>
          </div>
        ) : null}
      </div>
    );

    let content;

    if (userShowError && userShowError.status === 404) {
      return <NotFoundPage history={''} location={''} />;
    } else if (userShowError || queryListingsError) {
      content = (
        <p className={css.error}>
          <FormattedMessage id="ProfilePage.loadingDataFailed" />
        </p>
      );
    } else {
      content = mainContent;
    }

    const schemaTitle = intl.formatMessage(
      {
        id: 'ProfilePage.schemaTitle',
      },
      {
        name: displayName,
        // whoAmI: accountRole !== 'customer' ? whoAmI : 'Freedomly.io',
        whoAmI: whoAmI,
      }
    );

    const averageReviewRating = () => {
      const reviewRating = reviews.map(review => review.attributes?.rating);
      const average = reviewRating.reduce((a, b) => a + b, 0) / reviewRating.length;
      return average;
    };

    const schemaReviews = combinedReviews?.map(review => {
      const publishedDateTemp = new Date(review.createdAt);
      const reviewRating = review.rating;
      const reviewBody = review.content;
      const reviewAuthor = review.displayName;
      const year = publishedDateTemp.getFullYear();
      const month = publishedDateTemp.getMonth();
      const day = publishedDateTemp.getDate();
      const datePublished = `${year}-${month + 1}-${day}`;

      return {
        '@type': 'Review',
        author: {
          '@type': 'Person',
          name: reviewAuthor,
        },
        datePublished: datePublished,
        reviewBody: reviewBody,
        reviewRating: {
          '@type': 'Rating',
          ratingValue: reviewRating,
          bestRating: '5',
          worstRating: '1',
        },
      };
    });

    const schema = {
      '@context': 'http://schema.org',
      '@type': 'ProfilePage',
      name: schemaTitle,
      review: schemaReviews,
      mainEntity: {
        '@type': 'Person',
        name: displayName,
        jobTitle: whoAmI,
      },
    };

    if (reviews.length > 0) {
      schema.aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: averageReviewRating(),
        reviewCount: reviews.length,
      };
    }

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
        schema={schema}
        noIndexing={noIndexing}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfilePage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.main}>
            {isCurrentUser ? (
              <div className={css.modifyServiceHeading}>
                <h3 className={css.modifyServiceTitle}>
                  <FormattedMessage id="ProfilePage.modifyServiceTitle" />
                </h3>
                <div className={css.editProfileLinkDesktop}>
                  <FaPenAlt />
                  <NamedLink name="ProfileSettingsPage">
                    <h3>
                      <FormattedMessage id="ProfilePage.editProfileLinkDesktop" />
                    </h3>
                  </NamedLink>
                </div>
              </div>
            ) : null}
            <div className={css.mainContainer}>
              <LayoutWrapperSideNav className={css.aside}>{asideContent}</LayoutWrapperSideNav>
              {content}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

const { bool, arrayOf, number, shape } = PropTypes;

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  queryListingsError: propTypes.error,
  listings: arrayOf(propTypes.listing).isRequired,
  // reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    userId,
    userShowError,
    queryListingsError,
    userListingRefs,
    reviews,
    queryReviewsError,
    offPlatformTestimonials,
    queryOffPlatformTestimonialsError,
    offPlatformReviews,
    queryOffPlatformReviewsError,
    // profileUserName,
    // profileUserNameError,
  } = state.ProfilePage;
  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  const listings = getMarketplaceEntities(state, userListingRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    queryListingsError,
    listings,
    reviews,
    queryReviewsError,
    offPlatformTestimonials,
    queryOffPlatformTestimonialsError,
    offPlatformReviews,
    queryOffPlatformReviewsError,
    // profileUserName,
    // profileUserNameError,
  };
};

const mapDispatchToProps = dispatch => ({
  onQueryOffPlatformReviews: userId => dispatch(queryOffPlatformReviews(userId)),
  onQueryOffPlatformTestimonials: userId => dispatch(queryOffPlatformTestimonials(userId)),
});

const ProfilePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(ProfilePageComponent);

ProfilePage.loadData = params => {
  const id = new UUID(params.id);
  return loadData(id);
};

export default ProfilePage;
