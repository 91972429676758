import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

const OffPlatformUserDisplayName = props => {
  const { rootClassName, className, authorName } = props;

  const classes = classNames(rootClassName, className);
  return <span className={classes}>{authorName}</span>;
};

OffPlatformUserDisplayName.defaultProps = {
  rootClassName: null,
  className: null,
};

OffPlatformUserDisplayName.propTypes = {
  rootClassName: string,
  className: string,
};

export default OffPlatformUserDisplayName;
