import React from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';

import { Form, Button, SecondaryButton, FieldCheckbox } from '../../components';

import { injectIntl } from '../../util/reactIntl';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SignupProviderForms.css';

const SignupProviderIndexForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          onPrev,
          form,
          intl,
          invalid,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid;

        const heading = intl.formatMessage({ id: 'SignupProviderForms.indexingHeading' });

        const description = (
          <FormattedMessage
            id="SignupProviderForms.indexingDescription"
            values={{
              lineBreak: <br />,
            }}
          />
        );

        const nextButtonText = intl.formatMessage({
          id: 'SignupProviderForms.nextButton',
        });
        const previousButtonText = intl.formatMessage({
          id: 'SignupProviderForms.previousButton',
        });

        return (
          <div className={classes}>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <h2 className={css.mainHeading}>{heading}</h2>
              <p className={css.description}>{description}</p>
              <div className={css.noIndexingContainer}>
                <FieldCheckbox
                  id="noIndexing"
                  name="noIndexing"
                  label={intl.formatMessage({
                    id: 'ProfileSettingsForm.noIndexingLabel',
                  })}
                />
                <div className={css.noIndexingInfo}>
                  {intl.formatMessage({ id: 'ProfileSettingsForm.noIndexingInfo1' })}
                </div>
              </div>

              <div className={css.submitContainer}>
                <SecondaryButton rootClassName={css.backButton} type="button" onClick={onPrev}>
                  {previousButtonText}
                </SecondaryButton>
                <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                  {nextButtonText}
                </Button>
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

export default injectIntl(SignupProviderIndexForm);
