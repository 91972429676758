import React, { useState } from 'react';
import { injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { FormattedMessage } from '../../util/reactIntl';

import { ProfileSettingsContainer as Container, Modal } from '../../components';
import css from './Container.css';

const CodeOfConductContainerComponent = ({ profileUser, button, intl, isValidProvider }) => {
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const heading = intl.formatMessage({ id: 'ProfileSettingsForm.cocHeading' });
  const text = intl.formatMessage({ id: 'ProfileSettingsForm.cocText' });
  const textAccepted = intl.formatMessage({ id: 'ProfileSettingsForm.cocTextAccepted' });
  const openCoc = intl.formatMessage({ id: 'ProfileSettingsForm.cocOpenCoc' });

  const headingAccepted = intl.formatMessage({ id: 'SignupProviderForms.cocHeadingAccepted' });

  const descriptionAccepted = (
    <FormattedMessage
      id="SignupProviderForms.cocDescriptionAccepted"
      values={{
        lineBreak: <br />,
      }}
    />
  );
  const subHeading = intl.formatMessage({ id: 'SignupProviderForms.cocSubHeading' });

  const listItemTitle1 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem1' });
  const listItemTitle2 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem2' });
  const listItemTitle3 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem3' });
  const listItemTitle4 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem4' });
  const listItemTitle5 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem5' });
  const listItemTitle6 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem6' });

  const listItemText1 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText1' });
  const listItemText2 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText2' });
  const listItemText3 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText3' });
  const listItemText4 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText4' });
  const listItemText5 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText5' });
  const listItemText6 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText6' });

  const cocAccepted = profileUser?.attributes?.profile?.publicData?.cocAccepted || '';

  const cocModal = (
    <Modal
      id="ProfileSettingsForm.coc"
      isOpen={tosModalOpen}
      onClose={() => setTosModalOpen(false)}
      onManageDisableScrolling={manageDisableScrolling}
      usePortal
    >
      <div className={css.cocModal}>
        <h2 className={css.mainHeading}>{headingAccepted}</h2>
        <p className={css.description}>{descriptionAccepted}</p>
        <div className={css.subHeadingCOC}>{subHeading}</div>
        <div className={css.listItem}>
          <div className={css.listItemTitle}>{listItemTitle1}</div>
          <div className={css.listItemText}>{listItemText1}</div>
        </div>
        <div className={css.listItem}>
          <div className={css.listItemTitle}>{listItemTitle2}</div>
          <div className={css.listItemText}>{listItemText2}</div>
        </div>
        <div className={css.listItem}>
          <div className={css.listItemTitle}>{listItemTitle3}</div>
          <div className={css.listItemText}>{listItemText3}</div>
        </div>
        <div className={css.listItem}>
          <div className={css.listItemTitle}>{listItemTitle4}</div>
          <div className={css.listItemText}>{listItemText4}</div>
        </div>
        <div className={css.listItem}>
          <div className={css.listItemTitle}>{listItemTitle5}</div>
          <div className={css.listItemText}>{listItemText5}</div>
        </div>
        <div className={css.listItem}>
          <div className={css.listItemTitle}>{listItemTitle6}</div>
          <div className={css.listItemText}>{listItemText6}</div>
        </div>
      </div>
    </Modal>
  );

  const container = (
    <Container heading={' '} button={button}>
      <div>
        <div className={css.sectionTitle}>{heading}</div>

        {cocAccepted ? (
          <div className={css.cocContainer}>
            <BsFillCheckCircleFill className={css.cocIcon} />
            <div className={css.cocText}>{textAccepted}</div>
          </div>
        ) : (
          <div className={css.cocContainer}>
            <BsFillCheckCircleFill className={css.cocIconNotAccepted} />
            <div className={css.cocText}>{text}</div>
          </div>
        )}
        {!button ? (
          <div className={css.openCoc} onClick={() => setTosModalOpen(true)}>
            {openCoc}
          </div>
        ) : null}
      </div>
      {cocModal}
    </Container>
  );

  return container;
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const CodeOfConductContainer = compose(
  connect(mapDispatchToProps),
  injectIntl
)(CodeOfConductContainerComponent);

export default CodeOfConductContainer;
