import React from 'react';

import css from './SuccessStoryBanner.css';

import { FormattedMessage } from 'react-intl';

import { NamedLink } from '../../components';

const SuccessStoryBanner = props => {
  const { imgSrc, textId, fromId, companyId, pageName, buttonTxtId } = props;

  const text = <FormattedMessage id={textId} />;
  const from = <FormattedMessage id={fromId} />;
  const company = <FormattedMessage id={companyId} />;

  return (
    <div className={css.container}>
      <div className={css.contentContainer}>
        <div className={css.text}>{text}</div>
        <div className={css.imgFromCompanyContainer}>
          <div className={css.imageContainer}>
            <img src={imgSrc} alt={from} />
          </div>
          <div>
            <div className={css.from}>{from}</div>
            <div className={css.company}>{company}</div>
          </div>
        </div>
      </div>

      <NamedLink name={pageName} className={css.button}>
        <FormattedMessage id={buttonTxtId} />
      </NamedLink>
    </div>
  );
};

export default SuccessStoryBanner;
