import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconEnquiry, NamedLink } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import { FaCheckCircle } from 'react-icons/fa';
import { CiWarning } from 'react-icons/ci';

import css from './EnquiryForm.css';

const AT_RE = /@/i;
//eslint-disable-next-line
const PH_NO_RE = /\s?(\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{1,3}\)?[\s.-]?\d{3}[\s.-]?\d{4,5}/;
const PH_NO_RE2 = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/;
const LINK_RE = /[(http(s)?):(www)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/;

const EnquiryFormComponent = props => {
  const [messageNotValid, setMessageNotValid] = React.useState(true);
  return (
    <FinalForm
      {...props}
      mutators={{ setFieldData }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          showHiringSteps,
          submitButtonWrapperClassName,
          formId,
          handleSubmit,
          inProgress,
          intl,
          listingTitle,
          authorDisplayName,
          sendEnquiryError,
          currentUser,
          values,
        } = fieldRenderProps;

        const messageValidation = value => {
          if (value && AT_RE.test(value)) {
            setMessageNotValid(true);
            return true;
          }
          if (value && LINK_RE.test(value)) {
            setMessageNotValid(true);
            return true;
          }
          if (value && PH_NO_RE.test(value)) {
            setMessageNotValid(true);
            return true;
          }
          if (value && PH_NO_RE2.test(value)) {
            setMessageNotValid(true);
            return true;
          }
          if (value && value === '') {
            setMessageNotValid(true);
            return true;
          }
          setMessageNotValid(false);
          return false;
        };

        const warningIcon = <CiWarning />;

        const messageLabel = intl.formatMessage(
          {
            id: 'EnquiryForm.messageLabel',
          },
          { authorDisplayName }
        );
        const messagePlaceholder = intl.formatMessage(
          {
            id: 'EnquiryForm.messagePlaceholder',
          },
          { authorDisplayName }
        );
        const messageRequiredMessage = intl.formatMessage({
          id: 'EnquiryForm.messageRequired',
        });

        const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

        const contactInformationErrorMessage = intl.formatMessage({
          id: 'EnquiryForm.contactInformationErrorMessage',
        });

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = submitInProgress || messageNotValid;

        const canEnquire =
          currentUser?.profileImage !== null && currentUser?.attributes.profile.bio !== null;

        const hiringSteps = showHiringSteps ? (
          <div className={css.contactHelpBlock}>
            <div className={css.contactHelp}>
              <FaCheckCircle className={css.helpIcon} />
              <FormattedMessage id="EnquiryForm.EnquiryFormHelpLine1" />
            </div>
            <div className={css.contactHelp}>
              <FaCheckCircle className={css.helpIcon} />
              <FormattedMessage id="EnquiryForm.EnquiryFormHelpLine2" />
            </div>
            <div className={css.contactHelp}>
              <FaCheckCircle className={css.helpIcon} />
              <FormattedMessage id="EnquiryForm.EnquiryFormHelpLine3" />
            </div>
          </div>
        ) : null;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <IconEnquiry className={css.icon} />

            {canEnquire ? (
              <div>
                <h2 className={css.heading}>
                  <FormattedMessage id="EnquiryForm.heading" values={{ listingTitle }} />
                </h2>
                <FieldTextInput
                  className={css.field}
                  type="textarea"
                  name="message"
                  id={formId ? `${formId}.message` : 'message'}
                  label={messageLabel}
                  placeholder={messagePlaceholder}
                  validate={messageRequired}
                  warningMessage={contactInformationErrorMessage}
                  showWarning={messageValidation(values.message)}
                  icon={warningIcon}
                />
                {hiringSteps}
                <div className={css.checkboxContainer}>
                  <FormattedMessage
                    id="EnquiryForm.checkboxTOS"
                    values={{
                      b: chunks => (
                        <b
                          style={{
                            fontWeight: 600,
                            textDecoration: 'none',
                            cursor: 'default',
                          }}
                        >
                          {chunks}
                        </b>
                      ),
                    }}
                  />
                </div>
                <div className={submitButtonWrapperClassName}>
                  {sendEnquiryError ? (
                    <p className={css.error}>
                      <FormattedMessage id="EnquiryForm.sendEnquiryError" />
                    </p>
                  ) : null}

                  <PrimaryButton
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled || !values.message}
                  >
                    <FormattedMessage id="EnquiryForm.submitButtonText" />
                  </PrimaryButton>
                </div>
              </div>
            ) : (
              <div className={css.cantEnquireContainer}>
                <h2 className={css.heading}>
                  <FormattedMessage id="EnquiryForm.headingCantEnquire" />
                </h2>
                <p>
                  <FormattedMessage id="EnquiryForm.messageCantEnquire" />
                </p>
                <NamedLink name="ProfileSettingsPage" className={css.linkCantEnquire}>
                  <FormattedMessage id="EnquiryForm.buttonCantEnquire" />
                </NamedLink>
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};

EnquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

EnquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EnquiryForm = compose(injectIntl)(EnquiryFormComponent);

EnquiryForm.displayName = 'EnquiryForm';

export default EnquiryForm;
