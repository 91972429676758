import { denormalisedResponseEntities, ensureOwnListing } from '../util/data';
import { storableError } from '../util/errors';
import { fetchUnreadTxIds, fetchNewApplicationTxIds, generateIntercomHmac } from '../util/api';

import { LISTING_STATE_DRAFT } from '../util/types';
import config from '../config';
import * as log from '../util/log';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { types as sdkTypes, util as sdkUtil } from '../util/sdkLoader';
import { addMarketplaceEntities, getListingsById } from './marketplaceData.duck';
import { resultIds } from '../containers/ProjectSearchPage/ProjectSearchPage.duck';
import { USER_ROLES } from '../components/ModalCompleteProfile/ModalCompleteProfile';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATION_REFS = 'app/user/FETCH_CURRENT_USER_NOTIFICATION_REFS';

export const FETCH_CURRENT_USER_APPLICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_APPLICATIONS_REQUEST';
export const FETCH_CURRENT_USER_APPLICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_APPLICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_APPLICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_APPLICATIONS_ERROR';

export const FETCH_CURRENT_USER_APPLICATION_REFS = 'app/user/FETCH_CURRENT_USER_APPLICATION_REFS';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const FETCH_CURRENT_USER_PROFILE_LISTING_REQUEST =
  'app/user/FETCH_CURRENT_USER_PROFILE_LISTING_REQUEST';
export const FETCH_CURRENT_USER_PROFILE_LISTING_SUCCESS =
  'app/user/FETCH_CURRENT_USER_PROFILE_LISTING_SUCCESS';
export const FETCH_CURRENT_USER_PROFILE_LISTING_ERROR =
  'app/user/FETCH_CURRENT_USER_PROFILE_LISTING_ERROR';

export const FETCH_PAYOUT_PROFILE_LISTING_REQUEST = 'app/user/FETCH_PAYOUT_PROFILE_LISTING_REQUEST';
export const FETCH_PAYOUT_PROFILE_LISTING_SUCCESS = 'app/user/FETCH_PAYOUT_PROFILE_LISTING_SUCCESS';
export const FETCH_PAYOUT_PROFILE_LISTING_ERROR = 'app/user/FETCH_PAYOUT_PROFILE_LISTING_ERROR';
export const CURRENT_USER_HAS_APPROPRIATE_LISTING = 'app/user/CURRENT_USER_HAS_APPROPRIATE_LISTING';

export const FETCH_LISTINGS_BASED_ON_INTEREST_TAGS_REQUEST =
  'app/user/FETCH_LISTINGS_BASED_ON_INTEREST_TAGS_REQUEST';
export const FETCH_LISTINGS_BASED_ON_INTEREST_TAGS_SUCCESS =
  'app/user/FETCH_LISTINGS_BASED_ON_INTEREST_TAGS_SUCCESS';
export const FETCH_LISTINGS_BASED_ON_INTEREST_TAGS_ERROR =
  'app/user/FETCH_LISTINGS_BASED_ON_INTEREST_TAGS_ERROR';

export const UPDATE_FAVORITE_LISTINGS_REQUEST = 'app/user/UPDATE_FAVORITE_LISTINGS_REQUEST';
export const UPDATE_FAVORITE_LISTINGS_SUCCESS = 'app/user/UPDATE_FAVORITE_LISTINGS_SUCCESS';
export const UPDATE_FAVORITE_LISTINGS_ERROR = 'app/user/UPDATE_FAVORITE_LISTINGS_ERROR';

export const FETCH_FAVORITE_LISTINGS_REQUEST = 'app/user/FETCH_FAVORITE_LISTINGS_REQUEST';
export const FETCH_FAVORITE_LISTINGS_SUCCESS = 'app/user/FETCH_FAVORITE_LISTINGS_SUCCESS';
export const FETCH_FAVORITE_LISTINGS_ERROR = 'app/user/FETCH_FAVORITE_LISTINGS_ERROR';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.data.data.id,
    type: entity.data.data.type,
  }));

const initialState = {
  currentUserFetchInProgress: false,
  currentUser: null,
  currentUserFetchProgress: false,
  currentUserFetchSuccess: false,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserNotificationCount: 0,
  currentUserNotificationTx: [],
  currentUserNotificationTxRefs: [],
  currentUserNotificationCountError: null,
  currentUserApplicationCount: 0,
  currentUserApplicationTx: [],
  currentUserApplicationTxRefs: [],
  currentUserApplicationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  profileListing: null,
  fetchProfileListingInProgress: false,
  fetchProfileListingError: null,
  payoutProfileListing: null,
  fetchPayoutProfileListingInProgress: false,
  fetchPayoutProfileListingError: null,
  hasAppropriateListing: null,
  interestListingsLoading: true,
  interestListingIds: [],
  interestListingsError: null,
  favoriteListingsIds: [],
  favoriteListingsAddInProgress: false,
  favoriteListingsAddError: false,
  favoriteListingsFetchError: null,
  favoriteListingsFetchInProgress: false,
  favoriteListingsRemoveInProgress: false,
  favoriteListingsRemoveError: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_BASED_ON_INTEREST_TAGS_REQUEST:
      return {
        ...state,
        interestListingsLoading: true,
        interestListingsError: null,
        interestListingIds: [],
      };
    case FETCH_LISTINGS_BASED_ON_INTEREST_TAGS_SUCCESS:
      return {
        ...state,
        interestListingsLoading: false,
        interestListingIds: resultIds(payload.data),
        interestListingsError: null,
      };
    case FETCH_LISTINGS_BASED_ON_INTEREST_TAGS_ERROR:
      return {
        ...state,
        interestListingsLoading: false,
        interestListingsError: payload,
      };
    case CURRENT_USER_HAS_APPROPRIATE_LISTING:
      return { ...state, hasAppropriateListing: payload };
    case CURRENT_USER_SHOW_REQUEST:
      return {
        ...state,
        currentUserShowError: null,
        currentUserFetchProgress: true,
        currentUserFetchSuccess: false,
        hasAppropriateListing: null,
        currentUserFetchInProgress: true,
      };
    case CURRENT_USER_SHOW_SUCCESS:
      return {
        ...state,
        currentUser: mergeCurrentUser(state.currentUser, payload),
        currentUserFetchProgress: false,
        currentUserFetchSuccess: true,
        currentUserFetchInProgress: false,
      };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationTx: [],
        currentUserNotificationTxRefs: [],
        currentUserNotificationCountError: null,
        hasAppropriateListing: null,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return { ...state, currentUserHasListings: payload.hasListings };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        currentUserNotificationCount: payload.transactions.length,
        currentUserNotificationTx: payload.transactions,
      };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_NOTIFICATION_REFS: {
      return {
        ...state,
        currentUserNotificationTxRefs: entityRefs(payload),
      };
    }

    case FETCH_CURRENT_USER_APPLICATIONS_REQUEST:
      return { ...state, currentUserApplicationCountError: null };
    case FETCH_CURRENT_USER_APPLICATIONS_SUCCESS:
      return {
        ...state,
        currentUserApplicationCount: payload.transactions.length,
        currentUserApplicationTx: payload.transactions,
      };
    case FETCH_CURRENT_USER_APPLICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserApplicationCountError: payload };

    case FETCH_CURRENT_USER_APPLICATION_REFS: {
      return {
        ...state,
        currentUserApplicationTxRefs: entityRefs(payload),
      };
    }

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case FETCH_CURRENT_USER_PROFILE_LISTING_REQUEST:
      return {
        ...state,
        fetchProfileListingInProgress: true,
        fetchProfileListingError: null,
      };
    case FETCH_CURRENT_USER_PROFILE_LISTING_SUCCESS:
      return {
        ...state,
        fetchProfileListingProgress: false,
      };
    case FETCH_CURRENT_USER_PROFILE_LISTING_ERROR:
      return {
        ...state,
        fetchProfileListingProgress: false,
        fetchProfileListingError: payload,
      };

    case FETCH_PAYOUT_PROFILE_LISTING_REQUEST:
      return {
        ...state,
        fetchPayoutProfileListingInProgress: true,
        fetchPayoutProfileListingError: null,
      };
    case FETCH_PAYOUT_PROFILE_LISTING_SUCCESS:
      return {
        ...state,
        fetchPayoutProfileListingProgress: false,
        payoutProfileListing: payload,
      };
    case FETCH_PAYOUT_PROFILE_LISTING_ERROR:
      return {
        ...state,
        fetchPayoutProfileListingProgress: false,
        fetchPayoutProfileListingError: payload,
      };

    case UPDATE_FAVORITE_LISTINGS_REQUEST:
      return {
        ...state,
        favoriteListingsAddInProgress: true,
      };

    case UPDATE_FAVORITE_LISTINGS_SUCCESS:
      return {
        ...state,
        favoriteListingsAddInProgress: false,
        favoriteListingsIds: payload.data.data.attributes.profile.protectedData.favoriteListings,
      };

    case UPDATE_FAVORITE_LISTINGS_ERROR:
      return {
        ...state,
        favoriteListingsAddInProgress: false,
        favoriteListingsAddError: payload,
      };

    case FETCH_FAVORITE_LISTINGS_REQUEST:
      return {
        ...state,
        favoriteListingsFetchInProgress: true,
      };

    case FETCH_FAVORITE_LISTINGS_SUCCESS:
      return {
        ...state,
        favoriteListingsFetchInProgress: false,
        favoriteListingsIds: resultIds(payload.data),
      };

    case FETCH_FAVORITE_LISTINGS_ERROR:
      return {
        ...state,
        favoriteListingsFetchInProgress: false,
        favoriteListingsError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = hasListings => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

export const fetchCurrentUserNotificationTxRefs = transactions => ({
  type: FETCH_CURRENT_USER_NOTIFICATION_REFS,
  payload: transactions,
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserApplicationsRequest = () => ({
  type: FETCH_CURRENT_USER_APPLICATIONS_REQUEST,
});

export const fetchCurrentUserApplicationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_APPLICATIONS_SUCCESS,
  payload: { transactions },
});

export const fetchCurrentUserApplicationTxRefs = transactions => ({
  type: FETCH_CURRENT_USER_APPLICATION_REFS,
  payload: transactions,
});

const fetchCurrentUserApplicationsError = e => ({
  type: FETCH_CURRENT_USER_APPLICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const fetchCurrentUserProfileListingRequest = () => ({
  type: FETCH_CURRENT_USER_PROFILE_LISTING_REQUEST,
});

export const fetchCurrentUserProfileListingSuccess = () => ({
  type: FETCH_CURRENT_USER_PROFILE_LISTING_SUCCESS,
});
export const currentUserHasAppropriateListing = payload => ({
  type: CURRENT_USER_HAS_APPROPRIATE_LISTING,
  payload,
});

export const fetchCurrentUserProfileListingError = e => ({
  type: FETCH_CURRENT_USER_PROFILE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchPayoutProfileListingRequest = () => ({
  type: FETCH_PAYOUT_PROFILE_LISTING_REQUEST,
});

export const fetchPayoutProfileListingSuccess = listing => ({
  type: FETCH_PAYOUT_PROFILE_LISTING_SUCCESS,
  payload: listing,
});

export const fetchPayoutProfileListingError = e => ({
  type: FETCH_PAYOUT_PROFILE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchListingsBasedOnInterestTagsRequest = () => ({
  type: FETCH_LISTINGS_BASED_ON_INTEREST_TAGS_REQUEST,
});

export const fetchListingsBasedOnInterestTagsSuccess = response => ({
  type: FETCH_LISTINGS_BASED_ON_INTEREST_TAGS_SUCCESS,
  payload: { data: response.data },
});

export const fetchListingsBasedOnInterestTagsError = e => ({
  type: FETCH_LISTINGS_BASED_ON_INTEREST_TAGS_ERROR,
  error: true,
  payload: e,
});

export const updateFavoriteListingsRequest = () => ({
  type: UPDATE_FAVORITE_LISTINGS_REQUEST,
});
export const updateFavoriteListingsSuccess = response => ({
  type: UPDATE_FAVORITE_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const updateFavoriteListingsError = e => ({
  type: UPDATE_FAVORITE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchFavoriteListingsRequest = () => ({
  type: FETCH_FAVORITE_LISTINGS_REQUEST,
});

export const fetchFavoriteListingsSuccess = response => ({
  type: FETCH_FAVORITE_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const fetchFavoriteListingsError = e => ({
  type: FETCH_FAVORITE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const roleBasedListings = {
  'light-entrepreneur': 'service',
  customer: 'project',
  freelancer: 'service',
};

export const fetchCurrentUserHasListings = userRole => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    page: 1,
    per_page: 100,
  };

  // // {
  // //   CustomerListing: 'project',
  // //   FreelancerListing: 'service',
  // //   LightEnterpreneur: 'service'

  // // }

  const listingType = roleBasedListings[userRole];

  return sdk.ownListings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;

      const hasRoleBasedListing =
        response.data.data.filter(f => f.attributes.publicData.listingType === listingType).length >
        0;

      dispatch(currentUserHasAppropriateListing(!!hasRoleBasedListing));

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const currentUserId = currentUser && currentUser.id ? currentUser.id.uuid : '0';

  dispatch(fetchCurrentUserNotificationsRequest());

  fetchUnreadTxIds(currentUserId)
    .then(response => {
      const notificationTxIds = response.txIds.map(item => item.txId);
      const notificationsToBeFetched =
        notificationTxIds.length > 3 ? notificationTxIds.slice(0, 3) : notificationTxIds;
      const refs = notificationsToBeFetched.map(item =>
        dispatch(fetchCurrentUserNotificationTx(item))
      );

      Promise.all(refs).then(values => {
        dispatch(fetchCurrentUserNotificationTxRefs(values));
      });

      dispatch(fetchCurrentUserNotificationsSuccess(response.txIds));
    })
    .catch(e => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};

export const fetchCurrentUserApplications = () => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const currentUserId = currentUser && currentUser.id ? currentUser.id.uuid : '0';
  dispatch(fetchCurrentUserApplicationsRequest());

  fetchNewApplicationTxIds(currentUserId)
    .then(response => {
      const applicationTxIds = response.txIds.map(item => item.txId);
      const applicationssToBeFetched =
        applicationTxIds.length > 3 ? applicationTxIds.slice(0, 3) : applicationTxIds;
      const refs = applicationssToBeFetched.map(item =>
        dispatch(fetchCurrentUserApplicationTx(item))
      );

      Promise.all(refs).then(values => {
        dispatch(fetchCurrentUserApplicationTxRefs(values));
      });

      dispatch(fetchCurrentUserApplicationsSuccess(response.txIds));
    })
    .catch(e => dispatch(fetchCurrentUserApplicationsError(storableError(e))));
};

const fetchCurrentUserNotificationTx = txId => (dispatch, getState, sdk) => {
  return sdk.transactions
    .show(
      {
        id: txId,
        include: ['customer', 'provider', 'customer.profileImage', 'provider.profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      return response;
    });
};

const fetchCurrentUserApplicationTx = txId => (dispatch, getState, sdk) => {
  return sdk.transactions
    .show(
      {
        id: txId,
        include: ['customer', 'provider', 'customer.profileImage', 'provider.profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      return response;
    });
};

export const fetchCurrentUserProfileListing = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserProfileListingRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserProfileListingSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // profile listing, we only need at most one result.
    page: 1,
    per_page: 1,
    authorId: currentUser.id,
    pub_listingType: 'profile',
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const hasProfile = response.data.data && response.data.data.length > 0;
      const hasPublishedProfileListing =
        hasProfile &&
        ensureOwnListing(response.data.data[0]) !== undefined &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;

      dispatch(fetchCurrentUserProfileListingSuccess(!!hasPublishedProfileListing));

      return hasPublishedProfileListing;
    })
    .catch(e => dispatch(fetchCurrentUserProfileListingError(storableError(e))));
};

export const fetchPayoutProfileListing = () => (dispatch, getState, sdk) => {
  dispatch(fetchPayoutProfileListingRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchPayoutProfileListingSuccess(null));
    return Promise.resolve(null);
  }

  const params = {
    id: new UUID(config.leProfileListingId),
    include: ['author'],
  };

  return sdk.listings
    .show(params)
    .then(response => {
      return dispatch(addMarketplaceEntities(response));
    })
    .then(response => {
      const profileListings = getListingsById(getState(), [new UUID(config.leProfileListingId)]);

      return dispatch(fetchPayoutProfileListingSuccess(profileListings[0]));
    })
    .catch(e => dispatch(fetchPayoutProfileListingError(storableError(e))));
};

export const fetchListingsBasedOnInterestTags = ({ accountRole, interestTags = [] }) => (
  dispatch,
  getState,
  sdk
) => {
  const listingType =
    accountRole === USER_ROLES.CUSTOMER
      ? { pub_listingType: 'service' }
      : [USER_ROLES.LIGHT_ENTERPRENEUR, USER_ROLES.FREELANCER].includes(accountRole)
      ? { pub_listingType: 'project' }
      : null;

  const hasInterestTags = interestTags && interestTags.length > 0;

  const paramsIfNoInterestTags = {
    ...listingType,
    pub_hidden: false,
    perPage: 6,
    sort: ['meta_listingTier', 'createdAt'],
    include: ['author', 'images'],
    'fields.listing': [
      'availabilityPlan',
      'deleted',
      'createdAt',
      'state',
      'title',
      'description',
      'publicData',
      'geolocation',
      'price',
    ],
    'fields.user': [
      'profile.displayName',
      'profile.abbreviatedName',
      'profile.metadata',
      'profile.publicData',
    ],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  if (!listingType || !hasInterestTags) {
    dispatch(fetchListingsBasedOnInterestTagsRequest());
    return sdk.listings
      .query(paramsIfNoInterestTags)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchListingsBasedOnInterestTagsSuccess(response));
      })
      .catch(err => {
        dispatch(fetchListingsBasedOnInterestTagsError(storableError(err)));
      });

    // return Promise.resolve();
  }

  const params = {
    ...listingType,
    pub_hidden: false,
    pub_categoryNodes: 'has_any:' + interestTags.join(','),
    perPage: 6,
    sort: ['meta_listingTier', 'createdAt'],
    include: ['author', 'images'],
    'fields.listing': [
      'availabilityPlan',
      'deleted',
      'createdAt',
      'state',
      'title',
      'description',
      'publicData',
      'geolocation',
      'price',
    ],
    'fields.user': [
      'profile.displayName',
      'profile.abbreviatedName',
      'profile.metadata',
      'profile.publicData',
    ],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  dispatch(fetchListingsBasedOnInterestTagsRequest());

  return sdk.listings
    .query(params)
    .then(response => {
      if (response.data.data.length > 0) {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchListingsBasedOnInterestTagsSuccess(response));
      } else {
        return sdk.listings.query(paramsIfNoInterestTags).then(response => {
          dispatch(addMarketplaceEntities(response));
          dispatch(fetchListingsBasedOnInterestTagsSuccess(response));
        });
      }
    })
    .catch(err => {
      dispatch(fetchListingsBasedOnInterestTagsError(storableError(err)));
    });
};

export const fetchFavoriteListings = listingsIds => (dispatch, getState, sdk) => {
  dispatch(fetchFavoriteListingsRequest());

  const params = {
    ids: listingsIds,
    pub_hidden: false,
    include: ['author', 'images'],
    'fields.listing': [
      'availabilityPlan',
      'deleted',
      'createdAt',
      'state',
      'title',
      'description',
      'publicData',
      'geolocation',
      'price',
    ],
    'fields.user': [
      'profile.displayName',
      'profile.abbreviatedName',
      'profile.metadata',
      'profile.publicData',
    ],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const paramsIfNotFavoriteListings = {
    ids: [],
    pub_hidden: false,
    include: ['author', 'images'],
    'fields.listing': [
      'availabilityPlan',
      'deleted',
      'createdAt',
      'state',
      'title',
      'description',
      'publicData',
      'geolocation',
      'price',
    ],
    'fields.user': [
      'profile.displayName',
      'profile.abbreviatedName',
      'profile.metadata',
      'profile.publicData',
    ],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  if (listingsIds && listingsIds.length > 0) {
    return sdk.listings
      .query(params)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchFavoriteListingsSuccess(response));
      })
      .catch(err => dispatch(fetchFavoriteListingsError(err)));
  }

  return sdk.listings.query(paramsIfNotFavoriteListings).then(response => {
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchFavoriteListingsSuccess(response));
  });
};

export const updateFavoriteListings = listingId => (dispatch, getState, sdk) => {
  dispatch(updateFavoriteListingsRequest());

  sdk.currentUser
    .updateProfile({ protectedData: { favoriteListings: listingId } }, { expand: true })
    .then(async response => {
      dispatch(addMarketplaceEntities(response));
      await dispatch(fetchFavoriteListings(listingId));
    })
    .catch(err => {
      dispatch(updateFavoriteListingsError(storableError(err)));
    });
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());

  const { isAuthenticated } = getState().Auth;
  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount', 'stripeCustomer.defaultPaymentMethod'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };

  return sdk.currentUser
    .show(parameters)
    .then(async response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      const accountRole = currentUser?.attributes?.profile?.publicData?.account_role;
      const categoryTags = currentUser?.attributes?.profile?.publicData?.categories;
      const experienceTags = currentUser?.attributes?.profile?.publicData?.experience?.map(
        e => e.category
      );

      const interestTags = accountRole === 'customer' ? categoryTags : experienceTags;

      await dispatch(fetchListingsBasedOnInterestTags({ accountRole, interestTags }));

      await dispatch(
        fetchFavoriteListings(currentUser.attributes.profile.protectedData.favoriteListings)
      );

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      if (typeof window === 'object') {
        const categoryString = currentUser?.attributes?.profile?.publicData?.categories?.join(',');
        const experienceStringArray = currentUser?.attributes?.profile?.publicData?.experience?.map(
          function(e) {
            return e.category + ': ' + e.level;
          }
        );
        const languagesStringArray = currentUser?.attributes?.profile?.publicData?.languages?.map(
          function(e) {
            return e.language + ': ' + e.level;
          }
        );
        const hobbiesStringArray = currentUser?.attributes?.profile?.publicData?.hobbies?.map(
          function(e) {
            return e.category;
          }
        );
        const experienceString = experienceStringArray?.join(',');
        const languageString = languagesStringArray?.join(',');
        const hobbiesString = hobbiesStringArray?.join(',');
        const hmac = generateIntercomHmac(currentUser.id.uuid);
        Promise.resolve(hmac).then(hmac => {
          window.Intercom('update', {
            user_id: currentUser.id.uuid,
            user_hash: hmac.hmac,
            email: currentUser.attributes.email,
            firstName: currentUser.attributes.profile.firstName,
            lastName: currentUser.attributes.profile.lastName,
            name:
              currentUser.attributes.profile.firstName +
              ' ' +
              currentUser.attributes.profile.lastName,
            phone: currentUser.attributes.profile.protectedData.phoneNumber,
            textMessageNotifications:
              currentUser.attributes.profile.protectedData.textMessageNotifications,
            vatNumber: currentUser.attributes.profile.protectedData.vat_number,
            categories: categoryString,
            experience: experienceString,
            hobbies: hobbiesString,
            accountRole: currentUser.attributes.profile.publicData.account_role,
            location: currentUser?.attributes?.profile?.publicData?.location?.search,
            emailVerified: currentUser.attributes.emailVerified,
            projectDuration: currentUser.attributes.profile.protectedData.projectDuration,
            // budget: currentUser.attributes?.profile?.protectedData?.budget,
            lookingFor: currentUser.attributes?.profile?.protectedData?.lookingFor,
            language: languageString,
          });
        });
      }

      const userRole = currentUser?.attributes?.profile?.publicData?.account_role;

      dispatch(fetchCurrentUserHasListings(userRole));
      dispatch(fetchCurrentUserNotifications());
      dispatch(fetchCurrentUserApplications());
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }

      // Make sure auth info is up to date
      dispatch(authInfo());

      // Ensure the stripeConnected attribute visibility in publicData
      if (
        currentUser.attributes.stripeConnected &&
        !currentUser.attributes.profile.publicData.stripeConnected
      ) {
        return sdk.currentUser.updateProfile(
          {
            publicData: {
              stripeConnected: currentUser.attributes.stripeConnected,
            },
          },
          {}
        );
      }

      return Promise.resolve(null);
    })
    .then(() => {
      // Make sure the user profile listing exists
      return dispatch(fetchCurrentUserProfileListing());
    })
    .then(() => {
      // Return the light entrepreneur profile listing for payouts
      return dispatch(fetchPayoutProfileListing());
    })
    .then(() => {
      return sdk.currentUser.show(parameters);
    })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};
